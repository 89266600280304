import { ERROR_TOAST, SUCCESS_TOAST, SWR_KEYS } from '@/constants'
import { AssociationPaymentsService } from '@/services'
import {
  AssociationPayment,
  CreateAssociationPaymentValues,
  UpdateAssociationPaymentValues
} from '@/types'
import { useToast } from '@chakra-ui/react'
import { mutate } from 'swr'

export function useAssociationsPayment(): {
  findOne: (id: string) => Promise<AssociationPayment>
  create: (
    values: CreateAssociationPaymentValues
  ) => Promise<AssociationPayment>
  update: (
    values: UpdateAssociationPaymentValues
  ) => Promise<AssociationPayment>
  remove: (
    associationPayment: AssociationPayment,
    associationDatePaidUp: string
  ) => Promise<null>
  checkIfExist: (reference: string) => Promise<boolean>
} {
  const toast = useToast()

  const findOne = async (id: string): Promise<AssociationPayment> => {
    const { data, error } = await AssociationPaymentsService.findOne(id)
    if (error) {
      toast({
        ...ERROR_TOAST,
        description: error
      })
      // throw error so that it will be caught where this function is used
      throw new Error(error)
    }
    return data
  }

  const create = async (values: CreateAssociationPaymentValues) => {
    const { data, error } = await AssociationPaymentsService.create(values)
    if (error) {
      toast({
        ...ERROR_TOAST,
        description: error
      })

      // throw error so that it will be caught where this function is used
      throw new Error(error)
    }
    if (data) {
      await mutate(SWR_KEYS.associationPayments)
    }
    return data
  }

  const update = async (values: UpdateAssociationPaymentValues) => {
    const { data, error } = await AssociationPaymentsService.update(values)
    if (error) {
      toast({
        ...ERROR_TOAST,
        description: error
      })
      // throw error so that it will be caught where this function is used
      throw new Error(error)
    }
    if (data) {
      await mutate(SWR_KEYS.associationPayments)
    }
    return data
  }

  const remove = async (
    associationPayment: AssociationPayment,
    associationDatePaidUp: string
  ) => {
    const { error: deleteError } = await AssociationPaymentsService.remove(
      associationPayment.id,
      associationDatePaidUp
    )

    if (deleteError) {
      toast({
        ...ERROR_TOAST,
        description: deleteError
      })
    } else {
      toast({
        ...SUCCESS_TOAST,
        description: `${associationPayment.reference} verenigingsbetaling was suksesvol verwyder`
      })

      await mutate(SWR_KEYS.associationPayments)
    }

    return null
  }

  const checkIfExist = async (reference: string): Promise<boolean> => {
    const { data, error } = await AssociationPaymentsService.checkIfExist(
      reference
    )
    if (error) {
      toast({
        ...ERROR_TOAST,
        description: error
      })
      // throw error so that it will be caught where this function is used
      throw new Error(error)
    }
    return data
  }

  return {
    findOne,
    create,
    update,
    remove,
    checkIfExist
  }
}
