import { VoucherUpdateErrorMessages } from '@/constants'
import { Voucher } from '@/types'
import { z } from 'zod'

export const createDefaultValues = (voucher: Voucher) => ({
  isActive: voucher.isActive,
  schools: voucher.schools.map((val) => ({
    value: val.id,
    label: val.title
  })),
  endDate: voucher.endDate,
  startDate: voucher.startDate,
  redeemEndDate: voucher.redeemEndDate,
  code: voucher.code,
  description: voucher.description,
  numberOfVouchers: `${voucher.numberOfVouchers}`,
  numberOfVouchersUsed: `${
    voucher.numberOfVouchers - voucher.numberOfVouchersAvailable
  }`
})

export const updateVoucherSchema = z
  .object({
    isActive: z.boolean(),
    schools: z.array(
      z.object({
        value: z.string(),
        label: z.string()
      })
    ),
    endDate: z.string(),
    startDate: z.string(),
    redeemEndDate: z.string(),
    code: z
      .string({ required_error: 'Die kode word vereis' })
      .regex(/^[A-Z1-9#]+$/, {
        message:
          'Die kode moet tussen 4 en 10 karakters lank wees en mag slegs A-Z en 1-9 bevat'
      })
      .min(4, 'Die kode moet ten minste 4 karakters lank wees')
      .max(10, 'Die kode mag nie meer dan 10 karakters bevat'),
    description: z.string(),
    numberOfVouchers: z
      .string()
      .refine(
        (data) => parseInt(data) > 0,
        "Die aantal kodes moet 'n positiewe getal wees"
      ),
    numberOfVouchersUsed: z.string()
  })
  .refine((data) => new Date(data.startDate) < new Date(data.endDate), {
    path: ['startDate'],
    message:
      VoucherUpdateErrorMessages['Start date is greater than the end date']
  })
  .refine((data) => new Date(data.startDate) < new Date(data.redeemEndDate), {
    path: ['startDate'],
    message:
      VoucherUpdateErrorMessages[
        'Start date is greater than the redeem end date'
      ]
  })
  .refine((data) => new Date(data.startDate) < new Date(data.redeemEndDate), {
    path: ['startDate'],
    message:
      VoucherUpdateErrorMessages[
        'Start date is greater than the redeem end date'
      ]
  })
  .refine((data) => new Date(data.redeemEndDate) <= new Date(data.endDate), {
    path: ['redeemEndDate'],
    message:
      VoucherUpdateErrorMessages['Redeem end date is greater than the end date']
  })
  .refine((data) => new Date(data.redeemEndDate) > new Date(data.startDate), {
    path: ['redeemEndDate'],
    message:
      VoucherUpdateErrorMessages['Redeem end date is less than the start date']
  })
  .refine((data) => new Date(data.endDate) > new Date(data.startDate), {
    path: ['endDate'],
    message: VoucherUpdateErrorMessages['End date is less than the start date']
  })
  .refine((data) => new Date(data.endDate) >= new Date(data.redeemEndDate), {
    path: ['endDate'],
    message:
      VoucherUpdateErrorMessages['End date is less than the redeem end date']
  })
  .refine(
    (data) =>
      parseInt(data.numberOfVouchers) >= parseInt(data.numberOfVouchersUsed),
    {
      path: ['numberOfVouchers'],
      message:
        VoucherUpdateErrorMessages[
          'New number of vouchers is less than amount used'
        ]
    }
  )

export type UpdateVoucherSchema = z.infer<typeof updateVoucherSchema>
