import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  button: {},
  list: {
    bg: 'secondary.500',
    borderRadius: 0,
    border: '1px solid',
    borderColor: 'secondary.400',
    boxShadow: 'lg'
  },
  item: {
    color: 'brand.500',
    bg: 'transparent',
    _hover: {
      bg: 'secondary.400'
    },
    _focus: {
      bg: 'secondary.400'
    }
  },
  groupTitle: {},
  command: {},
  divider: {}
})
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle })
