/**
 * For more info on customising the Stepper components
 * @see https://github.com/chakra-ui/chakra-ui/issues/7648
 */

const baseStyle = {
  title: {
    color: 'secondary.300',
    '&[data-status=active]': {
      color: 'brand.500'
    }
  },
  description: {},
  indicator: {
    borderColor: 'secondary.300',
    _active: {
      borderColor: 'brand.500'
    },
    '&[data-status=complete]': {
      border: '2px solid',
      borderColor: 'brand.500',
      backgroundColor: 'transparent'
    },

    '&[data-status=active]': {
      borderColor: 'brand.500',
      backgroundColor: 'brand.500'
    },

    '&[data-status=incomplete]': {
      borderColor: 'secondary.300'
    }
  },
  separator: {
    bg: 'secondary.300',
    _active: {
      bg: 'brand.500'
    },
    '&[data-status=complete]': {
      bg: 'brand.500'
    },

    '&[data-status=active]': {
      bg: 'secondary.300'
    },

    '&[data-status=incomplete]': {
      bg: 'secondary.300'
    }
  },
  number: {
    color: 'secondary.300',
    _active: {
      color: 'brand.500'
    },
    '&[data-status=complete]': {
      color: 'brand.500'
    },

    '&[data-status=active]': {
      color: 'secondary.500'
    },

    '&[data-status=incomplete]': {
      color: 'secondary.300'
    }
  }
}

export const stepperTheme = { baseStyle }
