import { AdminUser } from '../adminUser'
import { SchoolLookups } from '../lookups'
import {
  WolkskoolProductType,
  WolkskoolProducts
} from '../wolkskoolProductTypes'
import { WolkskoolRole } from '../wolkskoolRoles'
import { WolkskoolUser } from '../wolkskoolUser'

export enum DiscountTypes {
  DISCOUNT = 'Afslagkode',
  FREE_PRODUCT = 'Gratis produk'
}

export enum DiscountStatuses {
  INACTIVE = 'Onaktief',
  ACTIVE = 'Aktief',
  EXPIRED = 'Verval',
  DEACTIVATED = 'Gedeaktiveer'
}

export type Coupon = {
  id: string
  code: string
  description: string
  createdBy: string
  numberOfCoupons: number
  startDate: string
  endDate: string
  productTypes: string[]
  roles: string[]
  packageType: string
  period: number
  amountType: string
  amount: number
  createdAt?: string
  updatedAt?: string
  isActive: boolean
}

export type Voucher = {
  id: string
  code: string
  description: string
  createdBy: AdminUser
  numberOfVouchers: number
  numberOfVouchersAvailable: number
  startDate: string
  redeemEndDate: string
  endDate: string
  productTypes: WolkskoolProductType[]
  roles: WolkskoolRole[]
  schools: SchoolLookups[]
  createdAt: string
  updatedAt?: string
  isActive: boolean
}

export type VoucherAndCoupon = {
  code: string
  type: DiscountTypes
  status: DiscountStatuses
  used: number
  total: number
  createdAt: string
  products: WolkskoolProducts[]
} & (
  | { type: DiscountTypes.DISCOUNT; coupon: Coupon; voucher?: never }
  | { type: DiscountTypes.FREE_PRODUCT; voucher: Voucher; coupon?: never }
)

export type CreateFreeCouponPreviewValues = {
  couponType: DiscountTypes.FREE_PRODUCT
  products: string
  userTypes: string
  schools: string
  startDateValid: string
  endDateValid: string
  redeemEndDate: string
  code: string
  numberOfCodes: number
}

// This is a WIP and is currently disabled
export type CreateDiscountCoupon = {
  couponType: DiscountTypes.DISCOUNT
  products: string[]
  paymentPackage: string
  period: string
  discountType: string
  amountOfDiscount: string
  code: string
  numberOfCodes: number
}

export type UserVoucher = {
  id: string
  createdAt: string
  voucher: Voucher
  user: WolkskoolUser
}
