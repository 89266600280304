import { ERROR_MESSAGES } from '@/constants'
import API from '@/lib/api'
import { CronJob } from '@/types'
import { API_ROUTES, bugsnagLogger } from '@/utils'

const getAllCronJobs = async (): Promise<{
  data: CronJob[] | null
  error: string | null
}> => {
  try {
    const { data } = await API.get(API_ROUTES.jobs.records)

    if (data.error) {
      throw new Error(data.error)
    }

    return {
      data: data.data,
      error: null
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/paymentPackageJobs.getAllCronJobs'
    })
    return {
      data: null,
      error: ERROR_MESSAGES.jobs.findAll
    }
  }
}

export const JobsService = { getAllCronJobs }
