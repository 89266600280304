import { useAuth } from '@/context/AuthProvider'
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/navigation/routes'
import { Button, Center, Code, Heading, Icon, VStack } from '@chakra-ui/react'
import { FiHome } from 'react-icons/fi'
import { useNavigate, useRouteError } from 'react-router-dom'

function ErrorScreen(): React.ReactElement {
  const { isAuthenticated } = useAuth()
  const error = useRouteError() as Error
  const navigate = useNavigate()

  const navigationButton = isAuthenticated
    ? {
        text: 'Paneelbord',
        onClick: () => navigate(PROTECTED_PATHS.dashboard),
        leftIcon: <Icon as={FiHome} />
      }
    : {
        text: 'Teken in',
        onClick: () => navigate(PUBLIC_PATHS.login),
        leftIcon: <Icon as={FiHome} />
      }
  return (
    <Center flexDirection="column" padding={4} h="100vh">
      <Heading textStyle="h5">Iets het fout gegaan 🙈</Heading>
      <Button
        mt={5}
        leftIcon={navigationButton.leftIcon}
        onClick={navigationButton.onClick}
      >
        {navigationButton.text}
      </Button>

      {error?.message && error?.stack ? (
        <VStack
          maxW={{ base: '90%', md: '50%', lg: '50%' }}
          overflowX="scroll"
          alignItems="flex-start"
          border="1px solid"
          borderColor="secondary.400"
          p={5}
          mt={'10%'}
        >
          <Code color="secondary.400" bg="transparent">
            {error.message}
          </Code>
          <Code color="secondary.400" bg="transparent">
            {error.stack}
          </Code>
        </VStack>
      ) : null}
    </Center>
  )
}

export default ErrorScreen
