import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    borderRadius: 'sm',
    borderColor: 'brand.500',
    borderWidth: 'thin',
    backgroundColor: 'secondary.500',
    _focus: {
      borderColor: 'brand.500 !important',
      boxShadow: '0 0 0 0 #F58221 !important', // brand.500 color
      backgroundColor: 'secondary.500'
    },
    _hover: {
      backgroundColor: 'secondary.500'
    },
    _checked: {
      backgroundColor: 'secondary.500',
      borderColor: 'brand.500',
      _hover: {
        backgroundColor: 'secondary.500',
        borderColor: 'brand.500'
      }
    },
    _disabled: {
      backgroundColor: 'secondary.500',
      borderColor: 'brand.500',
      _checked: {
        backgroundColor: 'secondary.500',
        borderColor: 'brand.500'
      }
    }
  },
  icon: {
    color: 'brand.500'
  },
  container: {},
  label: {
    color: 'brand.500'
  }
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })
