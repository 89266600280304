export const SWR_KEYS = {
  adminUsers: 'adminUsers',
  newTransactions: 'newTransactions',
  useSpecificDayTransactions: (limit: number, offset: number, search: string) =>
    `specificDayTransactions-${limit}-${offset}-${search}`,
  wolkskoolUsers: 'wolkskoolUsers',
  wolkskoolUsersTransactions: (
    limit: number,
    pageIndex: number,
    search: string
  ) => `wolkskoolUsersTransactions-${limit}-${pageIndex}-${search}`,
  associationPayments: (
    limit: number,
    offset: number,
    search: string,
    sortColumn: string,
    sortDirection: string
  ) =>
    `associationPayments-${limit}-${offset}-${search}-${sortColumn}-${sortDirection}`,
  schools: 'schools',
  learner: 'learner',
  learners: (
    limit: number,
    offset: number,
    search: string,
    sortColumn: string,
    sortDirection: string
  ) => `learners-${limit}-${offset}-${search}-${sortColumn}-${sortDirection}`,
  teacher: 'teacher',
  teachers: (
    limit: number,
    offset: number,
    search: string,
    sortColumn: string,
    sortDirection: string
  ) => `teachers-${limit}-${offset}-${search}-${sortColumn}-${sortDirection}`,
  newTransactionsCount: (limit: number, offset: number, search: string) =>
    `newTransactionsCount-${limit}-${offset}-${search}`,
  userNewTransactions: 'userNewTransactions',
  paymentPackages: 'paymentPackages',
  paymentPackageUpdateSchedules: 'paymentPackageUpdateSchedules',
  grades: 'grades',
  subjects: 'subjects',
  vraagbankGrades: 'vraagbankGrades',
  wolkskoolRoles: 'wolkskoolRoles',
  wolkskoolProductTypes: 'wolkskoolProductTypes',
  vouchersAndCoupons: 'vouchersAndCoupons'
}
