import { ENVIRONMENTS } from '../config'

export const ENV_VARS = {
  ENVIRONMENT: import.meta.env.VITE_NODE_ENV,
  API_URL: import.meta.env.VITE_API_URL,
  REACT_CLIENT_URL: import.meta.env.VITE_REACT_CLIENT_URL,
  BUGSNAG_API_KEY: import.meta.env.VITE_BUGSNAG_API_KEY
}

export const isDevelopment = ENV_VARS.ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT
export const isStaging = ENV_VARS.ENVIRONMENT === ENVIRONMENTS.STAGING
export const isProduction = ENV_VARS.ENVIRONMENT === ENVIRONMENTS.PRODUCTION
