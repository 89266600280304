import { selectStyle } from '@/theme/styles'
import { Option } from '@/types/forms'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useController, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

type Props = {
  label: string
  name: string
  isLoading?: boolean
  options: Option[]
  setSelectedOption: (option: Option) => void
  placeholder?: string
}

/**
 * This is a searchable select component that uses react-select
 * @see https://react-select.com/home#getting-started
 */
export const Select = ({
  label,
  name,
  options,
  setSelectedOption,
  placeholder = "Kies 'n opsie",
  isLoading
}: Props) => {
  const { control } = useFormContext()

  const {
    fieldState: { error },
    field: { value }
  } = useController({
    name,
    control
  })

  const showError = !!error

  return (
    <FormControl isInvalid={showError}>
      <FormLabel>{label}</FormLabel>
      <ReactSelect
        name={name}
        options={options}
        isLoading={isLoading}
        onChange={(option) => setSelectedOption(option as Option)}
        placeholder={placeholder}
        defaultValue={options.find((option) => option.value === value)}
        loadingMessage={() => 'Besig om te laai...'}
        /**
         * For more info on styling the select component
         * @see https://react-select.com/styles
         */
        styles={selectStyle}
      />
      {showError && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
