import { ErrorScreen } from '@/containers'
import AuthProvider from '@/context/AuthProvider'
import { AuthPageWrap } from '@/layouts'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { buildProtectedRoute, buildPublicRoute } from './navigation.helpers'
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from './routes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    errorElement: <ErrorScreen />,
    children: [
      {
        path: '/',
        element: <AuthPageWrap />,
        children: [
          ...PROTECTED_ROUTES.map((route) => buildProtectedRoute(route))
        ]
      },
      ...PUBLIC_ROUTES.map((route) => buildPublicRoute(route))
    ]
  }
])

function Navigation() {
  return <RouterProvider router={router} />
}

export default Navigation
