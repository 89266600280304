import theme from '@/theme'
import { Box, BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

export function FancyTile({ children, onClick }: Readonly<BoxProps>) {
  return (
    <TileWrapper onClick={onClick}>
      <Box className="container">
        <Box className="box">
          {/* This span represents the floating circles */}
          <span></span>
          <Box className="content">{children}</Box>
        </Box>
      </Box>
    </TileWrapper>
  )
}

const TileWrapper = styled.div`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    cursor: pointer;
  }
  .container .box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 200px;
    transition: 0.5s;
  }
  .container .box::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    text-decoration: none;
    background: #fff;
    transform: skewX(15deg);
    transition: 0.5s;
  }
  .container .box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    background: #fff;
    filter: blur(30px);
    transform: skewX(15deg);
    transition: 0.5s;
  }
  .container .box:hover:before,
  .container .box:hover:after {
    left: 20px;
    width: calc(100% - 90px);
    transform: skewX(0deg);
  }
  .container .box:hover span::before {
    top: -20px;
    left: 15px;
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.brand[700]};
    opacity: 0.6;
  }
  .container .box:hover span::after {
    bottom: -10px;
    right: 50px;
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.brand[700]};
    opacity: 0.6;
  }
  .container .box:hover .content {
    left: -25px;
    padding: 40px 30px;
    cursor: pointer;
  }
  .container .box:nth-of-type(1):before,
  .container .box:nth-of-type(1):after {
    background: linear-gradient(
      315deg,
      ${theme.colors.secondary[700]},
      ${theme.colors.secondary[500]}
    );
  }

  .container .box span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
  }
  .container .box span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }
  .container .box span::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    border-radius: 30px;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    animation-delay: -1s;
  }
  .container .box .content {
    position: relative;
    z-index: 1;
    left: 0;
    padding: 20px 20px;
    color: #fff;
    border: 1px solid ${theme.colors.secondary[500]};
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
  }
  .container .box .content a:hover {
    color: #111;
    background-color: #fff;
  }

  @keyframes animate {
    0%,
    100% {
      transform: translateY(10px);
    }
    50% {
      transform: translate(-10px);
    }
  }
  footer {
    position: fixed;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  footer p {
    color: #fff;
  }
  footer a {
    color: #fff;
    text-decoration: none;
  }
`
