import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Outlet } from 'react-router-dom'

const Wrapper = styled.div`
  .wrapper {
    display: grid;
    grid-template-columns:
      1fr
      min(90vw, calc(100% - 64px))
      1fr;
  }

  .wrapper > * {
    grid-column: 2;
  }

  .full-bleed {
    width: 100%;
    grid-column: 1 / 4;
  }
`

function FullBleedWrap() {
  return (
    <Wrapper>
      <Box className="wrapper" data-testid="full-bleed-wrap">
        <Outlet />
      </Box>
    </Wrapper>
  )
}

export default FullBleedWrap
