import { zIndices } from '@/theme/styles'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { TfiClose } from 'react-icons/tfi'
import Logo from '../../Logo'
import LinkItems from '../LinkItems'

function MobileSidebar() {
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <Box data-testid="mobile-sidebar">
      <IconButton
        aria-label="menu button"
        icon={<Icon as={isOpen ? TfiClose : FiMenu} />}
        onClick={onToggle}
        position="fixed"
        top={5}
        right={6}
        zIndex={zIndices.overlay - 1}
      />
      <Drawer isOpen={isOpen} placement="left" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader py={2} px={5}>
            <Logo />
          </DrawerHeader>
          <DrawerBody>
            <LinkItems py={2} px={4} onClick={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default MobileSidebar
