import { GroupBase, StylesConfig } from 'react-select'
import theme from '..'

export const selectStyle:
  | StylesConfig<
      {
        label: string
        value: string
      },
      false,
      GroupBase<{
        label: string
        value: string
      }>
    >
  | undefined = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    background: 'inherit',
    borderColor: `${theme.colors.brand[500]}`,
    boxShadow: state.isFocused
      ? `0 0 0 1px ${theme.colors.brand[500]}`
      : 'none',
    color: `${theme.colors.brand[500]}`,
    '&:hover': {
      borderColor: `${theme.colors.brand[300]}`
    },
    fontSize: `${theme.textStyles.p.fontSize}`
  }),
  input: (provided) => ({
    ...provided,
    color: `${theme.colors.brand[300]}`
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: 'inherit',
    color: `${theme.colors.brand[500]}`,
    fontSize: `${theme.textStyles.p.fontSize}`,
    '&:hover': {
      backgroundColor: `${theme.colors.secondary[500]}`
    }
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary[400]}`,
    zIndex: 9999
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary[400]}`,
    color: 'black',
    borderRadius: 0,
    boxShadow: `0 0 0 1px${theme.colors.brand[400]}`
  }),
  singleValue: (provided) => ({
    ...provided,
    color: `${theme.colors.brand[300]}`
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `${theme.colors.brand[300]}`
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: `${theme.colors.brand[500]}`,
    '&:hover': {
      color: `${theme.colors.brand[300]}`
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.brand[500]}`,
    '&:hover': {
      color: `${theme.colors.brand[300]}`
    }
  })
}
