import { useCallback, useEffect, useRef } from 'react'

interface DebounceOptions {
  delay: number
  leading?: boolean
}

/**
 * The useDebounce hook takes a callback function and an options object as inputs
 *
 * @param callback callback function to be debounced
 * @param options delay: debounce delay in milliseconds, leading: optional flag for immediate execution on the leading edge
 * @returns debounced callback
 */
export function useDebounce<T extends any[]>(
  callback: (...args: T) => void,
  options: DebounceOptions
) {
  const { delay, leading = false } = options

  // Store the timer ID of the current debounce timeout.
  const debounceTimeout = useRef<NodeJS.Timeout | undefined>()
  const isFirstCall = useRef(true)

  const debouncedCallback = useCallback(
    function (...args: T) {
      // If there's an existing timeout, clear it to prevent multiple concurrent timeouts.
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      // Execute the callback immediately on the leading edge.
      if (leading && isFirstCall.current) {
        isFirstCall.current = false
        callback(...args)
      }

      // Set a new timeout to delay the execution of the original callback function.
      debounceTimeout.current = setTimeout(() => {
        debounceTimeout.current = undefined
        isFirstCall.current = true // Reset the isFirstCall flag after the delay.
        callback(...args)
      }, delay)
    },
    [callback, delay, leading]
  )

  // Clean up the timeout when the component unmounts to avoid memory leaks.
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, [])

  return debouncedCallback
}
