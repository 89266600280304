// standard local and session keyes
export const USER_STORAGE_KEY = 'user'
export const DATA_AUTH_TOKEN = 'data-auth'

export const LOGIN_TOAST_ID = 'login-toast'
export const LOGOUT_TOAST_ID = 'logout-toast'

/**
 * If you make changes here, update the AUTH_RESPONSE_MESSAGES constant the backend as well
 */
export const AUTH_RESPONSE_MESSAGES = {
  tokenExpired: 'jwt expired',
  unregistered: 'unregistered',
  resetPassword: 'reset password'
}
