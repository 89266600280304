import { ERROR_TOAST } from '@/constants'
import { JobsService } from '@/services'
import { CronJob } from '@/types'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DeveloperToolGridItem } from '../DeveloperToolGridItem'

export function ActiveCronJobs() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<CronJob[]>([])
  const toast = useToast()

  async function fetchData() {
    setIsLoading(true)
    const { data, error } = await JobsService.getAllCronJobs()
    setIsLoading(false)
    if (error) {
      toast({
        ...ERROR_TOAST,
        description: error
      })
      return
    }
    if (data) {
      setData(data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <DeveloperToolGridItem
      title="Active Cron Jobs"
      onRefresh={fetchData}
      isLoading={isLoading}
    >
      <Accordion variant="devTools" allowToggle>
        {data.map((val) => {
          const { name, ...rest } = val
          return (
            <AccordionItem key={val.name}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {val.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <VStack alignItems="flex-start">
                  {Object.entries(rest).map(([key, value]) => {
                    return (
                      <Box key={key}>
                        {key}: {value}
                      </Box>
                    )
                  })}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </DeveloperToolGridItem>
  )
}
