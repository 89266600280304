import { InputOption } from '@/types'
import { Select, SelectProps } from '@chakra-ui/react'
import { FC } from 'react'

type InputSelectProps = SelectProps & {
  options: InputOption[]
}
const InputSelect: FC<InputSelectProps> = ({ options, ...rest }) => {
  return (
    <Select {...rest}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

InputSelect.defaultProps = {
  placeholder: 'Kies opsie',
  borderRadius: 0,
  _hover: {
    borderColor: 'brand.300'
  }
}
export default InputSelect
