import { AdminUser } from '@/types'
import { z } from 'zod'

export const updateDefaultValues = (values: Partial<AdminUser>) => ({
  name: values?.name ?? '',
  surname: values?.surname ?? '',
  email: values?.email ?? ''
})

export const updateSchema = z.object({
  name: z.string().optional(),
  surname: z.string().optional(),
  email: z.string().email().optional()
})
