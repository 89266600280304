import {
  Textarea as ChakraTextarea,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  TextareaProps
} from '@chakra-ui/react'
import { ReactElement, useState } from 'react'
import {
  FieldError,
  FieldPath,
  FieldValues,
  useController,
  useFormContext
} from 'react-hook-form'

type TextInputProps = TextareaProps & {
  label?: string
  name: FieldPath<FieldValues>
  helperText?: string
}

export function Textarea({
  name,
  helperText,
  label,
  ...rest
}: TextInputProps): ReactElement {
  const { control } = useFormContext()
  const [errorArray, setErrorArray] = useState<FieldError[]>([])

  const {
    fieldState: { error },
    field
  } = useController({
    name,
    control
  })

  const showError = !!error

  if (
    Array.isArray(error) &&
    JSON.stringify(error) !== JSON.stringify(errorArray)
  ) {
    setErrorArray(error)
  } else if (!error && errorArray.length > 0) {
    setErrorArray([])
  }

  return (
    <FormControl isInvalid={showError}>
      <FormLabel>{label}</FormLabel>
      <ChakraTextarea {...field} {...rest} data-testid="textarea" />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {showError && (
        <Flex flexDir="column">
          {errorArray.length ? (
            errorArray.map((error, index) => (
              <FormErrorMessage key={`${error.message}_${index}`}>
                {error.message}
              </FormErrorMessage>
            ))
          ) : (
            <FormErrorMessage>{error.message}</FormErrorMessage>
          )}
        </Flex>
      )}
    </FormControl>
  )
}
