import { IS_LOCAL, bugsnagConfig } from '@/constants'
import Bugsnag from '@bugsnag/js'
import React from 'react'

!IS_LOCAL && Bugsnag.start(bugsnagConfig)

type Props = {
  readonly children: React.ReactNode
}

/**
 * Renders an error boundary component that wraps its children with Bugsnag's ErrorBoundary to capture React render errors.
 * If the environment is local, it renders the children directly without the error boundary.
 *
 * @param {Props} props - The component props.
 * @param {React.ReactNode} props.children - The children components to be wrapped by the error boundary.
 * @returns {React.ReactNode} - The rendered component.
 */
export function BugsnagErrorBoundary({ children }: Props) {
  if (IS_LOCAL) {
    return children
  }

  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
  return <ErrorBoundary>{children}</ErrorBoundary>
}
