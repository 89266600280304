export const WOLKSKOOL_USERS_API_ERROR_MESSAGES = {
  wolkskoolUsers: {
    checkIfEmailExisError: {
      400: 'Kon nie email verifieër nie.'
    },
    removeUserError: {
      400: 'Kon nie gebruiker verwyder nie',
      403: 'Gebruiker is klas- of raadsaaleienaar'
    },
    searchError: {
      400: 'Kon nie soekopdrag uitvoer nie'
    }
  }
}
