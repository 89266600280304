import { MobileSidebar, Sidebar } from '@/components'
import { RequireAuth } from '@/context/AuthProvider'
import { useResponsiveness } from '@/hooks'
import { Outlet } from 'react-router-dom'

function AuthPageWrap() {
  const { isMobile } = useResponsiveness()

  if (isMobile) {
    return (
      <RequireAuth>
        <Outlet />
        <MobileSidebar />
      </RequireAuth>
    )
  }

  return (
    <RequireAuth>
      <Sidebar>
        <Outlet />
      </Sidebar>
    </RequireAuth>
  )
}

export default AuthPageWrap
