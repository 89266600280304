import { FlashcardWord } from '@/types'
import { z } from 'zod'

export const updateDefaultValues = (flashCardWord: FlashcardWord) => ({
  word: flashCardWord.word,
  definition: flashCardWord.definition.definition,
  tags:
    flashCardWord.tags?.map((tag) => ({
      value: tag.tag.id,
      label: tag.tag.name
    })) ?? []
})

export const schema = z.object({
  word: z.string().min(1, { message: 'Woord word benodig' }),
  definition: z.string().min(1, { message: 'Definisie word benodig' }),
  tags: z
    .array(
      z.object({
        value: z.string(),
        label: z.string()
      })
    )
    .optional()
})
