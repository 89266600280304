import { z } from 'zod'

export const createPermissionsGroupSchema = z
  .object({
    groupName: z.string(),
    description: z.string(),
    users: z
      .array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      )
      .min(1, 'Ten minste een gebruiker word vereis'),
    permissions: z
      .array(z.string())
      .min(1, 'Ten minste een permissie word vereis')
  })
  .refine(
    (data) => {
      if (!data.users.length) {
        return true // Since users are required, we can ignore this error as it will be caught by the users validation
      }
      if (!data.groupName && data.users.length !== 1) {
        return false
      }
      return true
    },
    {
      message: 'Die groep naam word vereis as daar meer as een gebruiker is',
      path: ['groupName']
    }
  )

export type CreatePermissionsGroupSchema = z.infer<
  typeof createPermissionsGroupSchema
>
export const initialValues: CreatePermissionsGroupSchema = {
  groupName: '',
  description: '',
  users: [],
  permissions: []
}
