import { DiscountTypes, ItemSelectorItem } from '@/types'
import { MdOutlineDiscount } from 'react-icons/md'
import { TbGift } from 'react-icons/tb'

export const couponTypes: ItemSelectorItem[] = [
  {
    value: DiscountTypes.DISCOUNT,
    name: 'Afslag',
    icon: MdOutlineDiscount,
    disabled: true
  },
  {
    value: DiscountTypes.FREE_PRODUCT,
    name: 'Gratis produk',
    icon: TbGift
  }
]

export enum VoucherUpdateErrorMessages {
  'Voucher is active' = 'Die kode is steeds aktief',
  'Start date is greater than the end date' = 'Die begin datum is groter as die produk toegang einddatum',
  'Start date is greater than the redeem end date' = 'Die begin datum is groter as die inwissel einddatum',
  'New number of vouchers is less than amount used' = 'Die nuwe aantal kodes beskikbaar is minder as die hoeveelheid gebruikte kodes',
  'Redeem end date is less than the start date' = 'Die inwissel einddatum is kleiner as die begin datum',
  'Redeem end date is greater than the end date' = 'Die inwissel einddatum is groter as die produk toegang einddatum',
  'End date is less than the start date' = 'Die produk toegang einddatum is kleiner as die begin datum',
  'End date is less than the redeem end date' = 'Die produk toegang einddatum is kleiner as die inwissel einddatum',
  'Voucher code already exists' = 'Die kode bestaan alreeds',
  'Voucher has already been redeemed' = 'Die kode is reeds ingewissel'
}
