import { months } from '@/constants'
import {
  Box,
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select
} from '@chakra-ui/react'
import { format } from 'date-fns'
import af from 'date-fns/locale/af'
import React, { useEffect, useState } from 'react'
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useController, useFormContext } from 'react-hook-form'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { DatePickerWrapper } from './styles'

type DatePickerProps = Omit<
  ReactDatePickerProps,
  'minDate' | 'maxDate' | 'startDate' | 'endDate'
> & {
  name: string
  label?: React.ReactNode | string
  containerProps?: FlexProps
  helperText?: string
  placeholder?: string
  date?: Date | string | null
  startDate?: Date | string | null
  endDate?: Date | string | null
  minDate?: Date | string | null
  maxDate?: Date | string | null
  onChange?: (date: Date | null) => void
  onApply?: (date: Date | null) => void
}
registerLocale('af', af)

const selectStyle = {
  backgroundColor: 'secondary.500',
  color: 'brand.300',
  borderColor: 'brand.300',
  '&:hover': {
    backgroundColor: 'secondary.600',
    cursor: 'pointer'
  },
  '&:focus': {
    borderColor: 'blue.400',
    boxShadow: 'none'
  },
  '& option': {
    backgroundColor: 'secondary.500',
    color: 'brand.300'
  }
}

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  helperText,
  minDate = new Date(),
  maxDate,
  selected,
  onChange,
  onApply,
  date,
  startDate,
  endDate,
  dateFormat = 'dd-MM-yyyy',
  placeholder = "Kies 'n datum",
  ...rest
}) => {
  const { control } = useFormContext()
  const [isOpen, setIsOpen] = useState(false)
  const [tempDate, setTempDate] = useState<Date | null>(null)
  const [originalDate, setOriginalDate] = useState<Date | null>(null)
  const [currentViewDate, setCurrentViewDate] = useState(new Date())
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: date ? new Date(date) : ''
  })

  useEffect(() => {
    if (field.value) {
      setTempDate(new Date(field.value))
    } else if (date) {
      setTempDate(new Date(date))
      field.onChange(new Date(date))
    }
  }, [field.value, date])

  const handleChange = (date: Date | null) => {
    setTempDate(date)
    field.onChange(date)
    if (onChange) {
      onChange(date)
    }
  }

  const handleApply = () => {
    if (tempDate) {
      if (onChange) {
        onChange(tempDate)
      }
      if (onApply) {
        onApply(tempDate)
      }
    }
    setIsOpen(false)
  }

  const handleCancel = () => {
    setIsOpen(false)
    setTempDate(originalDate ? new Date(originalDate) : null)
  }

  return (
    <DatePickerWrapper>
      <Box position="relative">
        <FormControl isInvalid={!!error}>
          {label && <FormLabel>{label}</FormLabel>}
          <Box
            onClick={() => {
              setIsOpen(true)
              const fieldDate = field.value ? new Date(field.value) : null
              setTempDate(fieldDate)
              setOriginalDate(fieldDate)
              setCurrentViewDate(fieldDate || new Date())
            }}
            cursor="pointer"
            border="1px solid"
            outlineOffset={0}
            _focusVisible={{ boxShadow: 'none' }}
            _focus={{
              boxShadow: 'none'
            }}
            _active={{
              boxShadow: 'none'
            }}
          >
            <Input
              {...field}
              value={
                tempDate && isFinite(tempDate.getTime())
                  ? format(tempDate, `${dateFormat}`)
                  : ''
              }
              placeholder={placeholder}
              isReadOnly={true}
              border="none"
              type="text"
              data-testid={`${name}-date-picker-input`}
              mb={0}
              _focus={{ border: 'none' }}
            />
          </Box>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          <FormErrorMessage>{error?.message}</FormErrorMessage>
          {isOpen && (
            <Box
              position="absolute"
              zIndex={1}
              bg="secondary.700"
              mt={2}
              width="100%"
              borderWidth={1}
              borderColor="brand.500"
            >
              <ReactDatePicker
                popperPlacement="top"
                data-testid="date-picker"
                selected={tempDate}
                onChange={handleChange}
                onMonthChange={(date) => {
                  setCurrentViewDate(date)
                  setCurrentMonth(date.getMonth())
                  setCurrentYear(date.getFullYear())
                }}
                onYearChange={(date) => {
                  setCurrentViewDate(date)
                  setCurrentMonth(date.getMonth())
                  setCurrentYear(date.getFullYear())
                }}
                inline
                minDate={minDate ? new Date(minDate) : undefined}
                maxDate={maxDate ? new Date(maxDate) : undefined}
                startDate={startDate ? new Date(startDate) : undefined}
                endDate={endDate ? new Date(endDate) : undefined}
                dateFormat={dateFormat}
                locale="af"
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  changeYear,
                  changeMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled
                }) => (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    pt={4}
                    px={2}
                  >
                    <FiArrowLeft
                      onClick={() => {
                        if (!prevMonthButtonDisabled) {
                          const newDate = new Date(
                            currentYear,
                            currentMonth - 1,
                            1
                          )
                          setCurrentViewDate(newDate)
                          setCurrentMonth(newDate.getMonth())
                          decreaseMonth()
                        }
                      }}
                      cursor={
                        prevMonthButtonDisabled ? 'not-allowed' : 'pointer'
                      }
                      opacity={prevMonthButtonDisabled ? 0.5 : 1}
                      size={30}
                    />
                    <Select
                      value={currentViewDate.getMonth()}
                      onChange={(e) => {
                        const newMonth = parseInt(e.target.value)
                        const newDate = new Date(currentYear, newMonth, 1)
                        setCurrentViewDate(newDate)
                        setCurrentMonth(newMonth)
                        changeMonth(newMonth)
                      }}
                      size="sm"
                      mr={4}
                      ml={3}
                      sx={selectStyle}
                    >
                      {months.map((month, index) => (
                        <option key={month} value={index}>
                          {month}
                        </option>
                      ))}
                    </Select>
                    <Select
                      mr={2}
                      value={currentViewDate.getFullYear()}
                      onChange={(e) => {
                        const newYear = parseInt(e.target.value)
                        const newDate = new Date(newYear, currentMonth, 1)
                        setCurrentViewDate(newDate)
                        setCurrentYear(newYear)
                        changeYear(newYear)
                      }}
                      size="sm"
                      sx={selectStyle}
                    >
                      {Array.from(
                        { length: 50 },
                        (_, i) => new Date().getFullYear() + 10 - i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Select>
                    <FiArrowRight
                      onClick={() => {
                        if (!nextMonthButtonDisabled) {
                          const newDate = new Date(
                            currentYear,
                            currentMonth + 1,
                            1
                          )
                          setCurrentViewDate(newDate)
                          setCurrentMonth(newDate.getMonth())
                          increaseMonth()
                        }
                      }}
                      cursor={
                        nextMonthButtonDisabled ? 'not-allowed' : 'pointer'
                      }
                      opacity={nextMonthButtonDisabled ? 0.5 : 1}
                      size={30}
                    />
                  </Flex>
                )}
                {...rest}
              />
              <Flex mt={4} borderTop={'1px solid'} borderColor="grey.200" p={4}>
                <Button onClick={handleCancel} flex="1" variant="brand-outline">
                  Kanselleer
                </Button>
                <Button
                  onClick={handleApply}
                  mt={1}
                  flex="1"
                  fontSize="14px"
                  color="black !important"
                  backgroundColor="brand.500 !important"
                  borderWidth={1}
                  padding="10px 14px"
                  marginLeft={2}
                  _hover={{
                    textDecoration: 'none',
                    backgroundColor: 'brand.600 !important'
                  }}
                >
                  Pas toe
                </Button>
              </Flex>
            </Box>
          )}
        </FormControl>
      </Box>
    </DatePickerWrapper>
  )
}
