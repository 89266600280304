import { ENV_VARS } from '@/constants'
import axios from 'axios'

const API = axios.create({
  baseURL: ENV_VARS.API_URL,
  timeout: 30000
})

export const fetcher = (url: string) =>
  API.get(url).then((res) => res.data.data)

export const fetcherWithParams = (url: string, params: any) =>
  API.get(url, {
    params
  })
    .then((res) => {
      return res.data.data
    })
    .catch((e) => {
      return e
    })

export function setToken(token: string) {
  API.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${token}`
    return config
  })
}

export default API
