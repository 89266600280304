import {
  DatePicker,
  Select,
  TextInput,
  Textarea
} from '@/components/FormElements'
import { Loader } from '@/components/Loader'
import { EMAIL_REGEX } from '@/constants'
import { useAssociationsPayment, useDebounce, useSchools } from '@/hooks'
import { AssociationPayment, UpdateAssociationPaymentValues } from '@/types'
import {
  Button,
  Collapse,
  Flex,
  FormLabel,
  Grid,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { z } from 'zod'
import {
  getInputValidations,
  handleInputOnChange,
  handleInputOnKeyDown,
  handleReferenceCheckIfExist,
  updateForm
} from '../associationPayments.helpers'

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

// In order to be able to add and remove email inputs, each form value needs to be of type string.
type FormValues = {
  reference: string
  schoolId: string
  datePaidUp: string
  newEmailTextarea: string
  removedEmailsPaidUpDate: string
  [key: string]: string
}

type Props = {
  isOpen: boolean
  onClose: () => void
  initialValues: AssociationPayment
  onSuccess: () => void
}

export const UpdateAssociationPaymentModal = ({
  isOpen,
  onClose,
  initialValues,
  onSuccess
}: Props) => {
  const { findOne, update, checkIfExist } = useAssociationsPayment()
  const { data: schools, loadingSchools: schoolsIsLoading } = useSchools()
  const schoolsOptions = schools?.map((school) => ({
    value: school.id,
    label: school.title
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [showTextArea, setShowTextArea] = useState(false)
  const [emailRemoved, setEmailRemoved] = useState(false) // state to keep track if an email has been removed
  const [emailObjects, setEmailObjects] = useState<Record<string, string>>({})
  const [initialEmails, setInitialEmails] = useState<string[]>([])
  const emails = useMemo(() => Object.values(emailObjects), [emailObjects])

  const defaultValues: FormValues = {
    reference: initialValues.reference ?? '',
    schoolId: initialValues.schoolLookup.id,
    datePaidUp: initialValues.datePaidUp,
    newEmailTextarea: '',
    removedEmailsPaidUpDate: ''
  }

  const updateSchema = useMemo(() => {
    // Dynamic input fields validations
    const inputValidations = getInputValidations(emailObjects)

    // Combine the text area validation with the dynamic email validations
    return z.object({
      reference: z.string().min(1, { message: 'Verwysing word benodig' }),
      schoolId: z.string().optional(),
      datePaidUp: z.string().optional(),
      removedEmailsPaidUpDate: z
        .string()
        .refine(
          (val) => (emailRemoved ? !!val : true),
          'Datum vir verwyderde e-posse word benodig'
        ),
      newEmailTextarea: z
        .string()
        .refine((val) => (val ? EMAIL_REGEX.test(val) : true))
        .optional(),
      ...inputValidations
    })
  }, [emailObjects])

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(updateSchema)
  })

  const handleDebouncedReferenceCheckIfExist = useDebounce(
    handleReferenceCheckIfExist,
    {
      delay: 1000
    }
  )

  useEffect(() => {
    let isMounted = true
    if (initialValues && isOpen) {
      setIsLoading(true)
      findOne(initialValues.id)
        .then((data) => {
          if (isMounted && data.associationPaymentsItems?.length) {
            const emails = data.associationPaymentsItems.map(
              (item) => item.email
            )
            setInitialEmails(emails)
            let emailsString = emails.join(`\n`)
            if (data.associationPaymentsItems?.length === 1) {
              emailsString = emailsString + `\n` // add a new line if there's only one email
            }
            updateForm(
              'newEmailTextarea',
              [],
              emailsString,
              methods.setValue,
              setEmailObjects
            )
          }
        })
        .finally(() => setIsLoading(false))
    }
    return () => {
      isMounted = false
    }
  }, [isOpen])

  useEffect(() => {
    if (initialEmails.some((email) => !emails.includes(email))) {
      setEmailRemoved(true)
    }
  }, [emails, initialEmails])

  const onSubmit: SubmitHandler<typeof defaultValues> = (values) => {
    setIsLoading(true)
    const {
      reference,
      schoolId,
      datePaidUp,
      newEmailTextarea,
      removedEmailsPaidUpDate,
      ...rest
    } = values

    // Deduplicates and constructs a new array of emails, incorporating additional emails if provided.
    const newEmails = Array.from(
      new Set(
        newEmailTextarea
          ? [...Object.values(rest), newEmailTextarea] // Adds the newEmailTextarea value if it exists.
          : [...Object.values(rest)] // Uses the rest of the values if newEmailTextarea doesn't exist.
      )
    )

    // Initializes an object to hold the values that will be updated, starting with the association's ID.
    const updatedValues: UpdateAssociationPaymentValues = {
      id: initialValues.id
    }
    const INITIAL_NUMBER_OF_KEYS = 1 // initial number of keys of the updatedValues object

    // Checks if the form's reference, schoolId, or datePaidUp have changed from their initial values and updates them accordingly.
    if (reference !== initialValues.reference) {
      updatedValues.reference = reference
    }
    if (schoolId !== initialValues.schoolLookup.id) {
      updatedValues.schoolId = schoolId
    }
    if (datePaidUp !== initialValues.datePaidUp) {
      updatedValues.datePaidUp = new Date(datePaidUp)
    }

    if (emailRemoved) {
      updatedValues.removedEmailsPaidUpDate = new Date(removedEmailsPaidUpDate)
    }

    // Updates the email list if there are new emails and they differ from the initial emails.
    if (newEmails.length && !isEqual(newEmails, initialEmails)) {
      updatedValues.usersEmails = newEmails
    }

    // Cancel the update operation if there are no values to update.
    if (Object.keys(updatedValues).length === INITIAL_NUMBER_OF_KEYS) {
      setIsLoading(false)
      handleOnClose()
      return
    }

    // Attempts to update the values using a provided update function.
    update(updatedValues)
      .then(() => {
        handleOnClose()
        onSuccess()
      })
      .catch(() => {
        // need to catch this to not submit, the error is being handled by the hook
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleOnClose = () => {
    setEmailObjects({})
    onClose()
  }

  const handleToggleTextArea = () => {
    if (showTextArea) {
      methods.setValue('newEmailTextarea', '')
    }
    setShowTextArea((prev) => !prev)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="4xl">
      <ModalOverlay />
      {isLoading ? (
        <ModalContent h="20vh">
          <Loader />
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader>Wysig betaling</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <Stack>
                <TextInput
                  label="Verwysing"
                  name="reference"
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase()
                    handleDebouncedReferenceCheckIfExist(
                      methods,
                      checkIfExist,
                      value,
                      initialValues.reference
                    )
                    methods.setValue('reference', value)
                  }}
                />
                <Select
                  label="Skool"
                  name="schoolId"
                  isLoading={schoolsIsLoading}
                  options={schoolsOptions}
                  setSelectedOption={(option) =>
                    methods.setValue('schoolId', option.value)
                  }
                />
                <DatePicker
                  name="datePaidUp"
                  label="Datum"
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  date={new Date(methods.watch('datePaidUp'))}
                  onChange={(date) => {
                    if (date) {
                      methods.setValue('datePaidUp', date.toISOString())
                    }
                  }}
                />

                <Flex flexDir="column">
                  <HStack alignItems="baseline">
                    <Text as={FormLabel}>E-pos addresse</Text>
                    <IconButton
                      aria-label="add emails"
                      icon={<Icon as={showTextArea ? FaMinus : FaPlus} />}
                      variant="brand-outline"
                      size="xs"
                      rounded="full"
                      onClick={handleToggleTextArea}
                    />
                  </HStack>
                  <Collapse in={showTextArea}>
                    <Textarea
                      name="newEmailTextarea"
                      height="85px"
                      placeholder={`voorbeeld@epos.co.za \nvoorbeeld@epos.co.za \nvoorbeeld@epos.co.za`}
                      onChange={(e) => {
                        updateForm(
                          'newEmailTextarea',
                          emails,
                          e.target.value,
                          methods.setValue,
                          setEmailObjects
                        )
                      }}
                    />
                  </Collapse>
                  <Grid
                    templateColumns="repeat(3, 1fr)"
                    gap={2}
                    maxH="75vh"
                    overflow="scroll"
                  >
                    {Object.keys(emailObjects).map((key, index) => {
                      const isInvalid = Object.keys(
                        methods.formState.errors
                      ).includes(key)
                      return (
                        <TextInput
                          data-testid={`email-input-${index}`}
                          key={key}
                          mb={isInvalid ? undefined : 0}
                          name={key}
                          onKeyDown={(e) =>
                            handleInputOnKeyDown(
                              e,
                              key,
                              emailObjects,
                              setEmailObjects
                            )
                          }
                          onChange={(e) => {
                            const value = e.target.value.toLowerCase().trim()
                            methods.setValue(key, value)
                            handleInputOnChange(
                              value,
                              key,
                              emailObjects,
                              setEmailObjects
                            )
                          }}
                        />
                      )
                    })}
                  </Grid>
                </Flex>
                <Collapse
                  in={emailRemoved}
                  style={{
                    overflow: 'visible'
                  }}
                >
                  <DatePicker
                    name="removedEmailsPaidUpDate"
                    label="Nuwe datum vir verwyderde e-posse"
                    helperText="Kies 'n nuwe datum vir die eposse wat verwyder is van hierdie verenigingsbetaling."
                    dateFormat="dd-MM-yyyy"
                    date={
                      methods.watch('removedEmailsPaidUpDate')
                        ? new Date(methods.watch('removedEmailsPaidUpDate'))
                        : null
                    }
                    disabled={!emailRemoved}
                    onChange={(date) => {
                      if (date) {
                        methods.setValue(
                          'removedEmailsPaidUpDate',
                          date.toISOString()
                        )
                      }
                    }}
                    onApply={(date) => {
                      if (date) {
                        methods.setValue(
                          'removedEmailsPaidUpDate',
                          date.toISOString()
                        )
                      }
                    }}
                  />
                </Collapse>
              </Stack>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              isDisabled={isLoading}
            >
              Wysig
            </Button>
            <Button
              onClick={handleOnClose}
              isDisabled={isLoading}
              variant="brand-outline"
              ml={2}
            >
              Kanselleer
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  )
}
