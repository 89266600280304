import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys)

const devTools = definePartsStyle({
  button: {
    _hover: {
      color: 'brand.200'
    }
  },
  container: {
    borderColor: 'secondary.500'
  }
})

export const accordionTheme = defineMultiStyleConfig({
  variants: { devTools }
})
