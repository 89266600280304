import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    placeholder: 'Kies opsie',
    borderColor: 'brand.500',
    _focus: {
      zIndex: 1,
      borderColor: 'brand.500 !important',
      boxShadow: '0 0 0 1px #F58221 !important' // brand.500 color
    }
  },
  icon: {
    color: 'brand.500'
  }
})

export const selectTheme = defineMultiStyleConfig({ baseStyle })
