const authentication = {
  signIn: '/admin/auth/login',
  validateToken: '/admin/auth/validate'
}

const adminUsers = {
  records: '/admin/users',
  record: (userId?: string) =>
    userId ? `/admin/users/user/${userId}` : '/admin/users/user',
  register: '/admin/users/register',
  sendResetPasswordEmail: (userId: string) =>
    `/admin/users/send-reset-password-email/${userId}`,
  resendRegisterEmail: (userId: string) =>
    `/admin/users/resend-register-email/${userId}`,
  resetPassword: '/admin/users/reset-password',
  loginAs: '/admin/users/login-as',
  cancelRequest: (id: string) => `/admin/users/${id}/cancel-request/`,
  approveRequest: (id: string) => `/admin/users/${id}/approve-request/`
}

const newTransactions = {
  records: '/admin/new-transactions',
  userTransactions: (userId: string) =>
    `/admin/new-transactions/user-transactions/${userId}`,
  specificDayTransactions: '/admin/new-transactions/specific-day-transactions',
  dailyTransactionCount: '/admin/new-transactions/daily-transactions-count'
}

const wolkskoolUsers = {
  record: (id: string) => `/admin/wolkskool-users/${id}`,
  records: '/admin/wolkskool-users',
  search: (searchTerm: string) => `/admin/wolkskool-users/search/${searchTerm}`,
  usersWithTransactions: '/admin/wolkskool-users/users-with-transactions',
  checkIfEmailExist: (email: string) =>
    `/admin/wolkskool-users/checkIfEmailExist/${email}`
}

const associationPayments = {
  record: (id: string) => `/admin/associations-payments/${id}`,
  recordRemove: (id: string, associationDatePaidUp: string) =>
    `/admin/associations-payments/${id}&${associationDatePaidUp}`,
  records: '/admin/associations-payments',
  checkIfExist: (reference: string) =>
    `/admin/associations-payments/checkIfExist/${reference}`
}

const paymentPackages = {
  record: (id: string) => `/admin/payment-packages/${id}`,
  records: '/admin/payment-packages',
  updateSchedules: '/admin/payment-packages/update-schedules',
  updateSchedulesRecord: (id: string) =>
    `/admin/payment-packages/update-schedules/${id}`,
  cancelSchedulesRecord: (id: string) =>
    `/admin/payment-packages/update-schedules/cancel/${id}`
}

const jobs = {
  records: '/admin/cron-jobs'
}

const learners = {
  record: (id: string) => `/admin/wolkskool-users/learner/${id}`,
  records: '/admin/wolkskool-users/learners'
}

const teachers = {
  record: (id: string) => `/admin/wolkskool-users/teacher/${id}`,
  records: '/admin/wolkskool-users/teachers'
}

const grades = {
  records: '/admin/grades'
}

const subjects = {
  records: (gradeId: string) => `/admin/subjects/${gradeId}`
}

const vraagbankGrades = {
  records: '/admin/vraagbank-grades'
}

const vraagbankSubjects = {
  records: `/admin/vraagbank-subjects`
}

const vraagbankContentAreas = {
  records: `/admin/vraagbank-content-areas`
}

const tags = {
  records: '/admin/tags'
}

const flashcardWords = {
  records: '/admin/flashcard-words'
}

const lookups = {
  schools: '/admin/lookups/schools',
  schoolsBySearchValue: (searchValue: string) =>
    `/admin/lookups/schools/${searchValue}`,
  provinces: 'admin/lookups/provinces',
  jobs: 'admin/lookups/jobs',
  idTypes: 'admin/lookups/id-types'
}

const vouchers = {
  records: '/admin/vouchers',
  record: (id: string) => `/admin/vouchers/${id}`
}

const wolkskoolRoles = {
  records: '/admin/wolkskool-roles'
}
const wolkskoolProductTypes = {
  records: '/admin/product-types'
}
const vouchersAndCoupons = {
  records: '/admin/vouchers-and-coupons'
}
const newInhoudPermissions = {
  records: '/admin/new-inhoud-permissions',
  groups: '/admin/new-inhoud-permissions/groups'
}

export default {
  vraagbankContentAreas,
  associationPayments,
  vraagbankSubjects,
  vraagbankGrades,
  paymentPackages,
  newTransactions,
  authentication,
  wolkskoolUsers,
  flashcardWords,
  adminUsers,
  learners,
  teachers,
  subjects,
  lookups,
  vouchers,
  grades,
  jobs,
  tags,
  wolkskoolRoles,
  wolkskoolProductTypes,
  vouchersAndCoupons,
  newInhoudPermissions
}
