export const months = [
  "Januarie",
  "Februarie",
  "Maart",
  "April",
  "Mei",
  "Junie",
  "Julie",
  "Augustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];
