import { Flex, Spinner } from '@chakra-ui/react'
import React, { Suspense } from 'react'

type SuspenseWrapperProps = {
  children: React.JSX.Element
}
const SuspenseWrapper = ({ children }: SuspenseWrapperProps) => {
  const fallback = (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
      <Spinner data-testid="spinner" size="md" color="brand.500" />
    </Flex>
  )
  return <Suspense fallback={fallback}>{children}</Suspense>
}

export default SuspenseWrapper
