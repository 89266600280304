import { z } from 'zod'

export const registerDefaultValues = {
  name: '',
  surname: '',
  password: ''
}

export const registerSchema = z.object({
  name: z.string(),
  surname: z.string(),
  password: z.string({ required_error: 'Benodig n wagwoord' })
})
