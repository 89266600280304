import { drawerAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys)

const baseStyle = definePartsStyle({
  dialogContainer: {},
  dialog: { bg: 'secondary.600', color: 'brand.500' },
  content: {},
  header: {},
  body: {},
  footer: {}
})

export const drawerTheme = defineMultiStyleConfig({ baseStyle })
