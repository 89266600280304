import { z } from 'zod'

export const schema = z.object({
  name: z
    .string()
    .max(20, 'Naam is te lank')
    .min(1, { message: 'Naam word benodig' })
    .refine((name) => !/\d/.test(name), {
      message: 'Naam mag nie nommers bevat nie'
    })
    .refine((name) => /[a-zA-Z]/.test(name), {
      message: 'Naam moet ten minste een letter bevat'
    }),
  surname: z
    .string()
    .max(20, 'Van is te lank')
    .min(1, { message: 'Van word benodig' })
    .refine((name) => !/\d/.test(name), {
      message: 'Van mag nie nommers bevat nie'
    })
    .refine((name) => /[a-zA-Z]/.test(name), {
      message: 'Van moet ten minste een letter bevat'
    }),
  datePaidUp: z.string().optional().nullable(),
  dateTrialExpire: z.string().optional().nullable(),
  email: z.string().email({ message: 'Ongeldige e-posadres' }),
  mobile: z
    .string()
    .optional()
    .nullable()
    .refine(
      (mobile) =>
        mobile === null ||
        mobile === undefined ||
        /^\+?[1-9]\d{10}$/.test(mobile) ||
        /^\d{10}$/.test(mobile),
      {
        message: 'Selfoon nommer is ongeldig'
      }
    ),
  provinceLookups: z.string().min(1, { message: 'Provinsie word benodig' }),
  schoolLookups: z.string().min(1, { message: 'Skool word benodig' }),
  parentName: z
    .string()
    .max(20, 'Naam is te lank')
    .min(1, { message: 'Naam word benodig' })
    .refine((name) => !/\d/.test(name), {
      message: 'Naam mag nie nommers bevat nie'
    })
    .refine((name) => /[a-zA-Z]/.test(name), {
      message: 'Naam moet ten minste een letter bevat'
    }),
  parentSurname: z
    .string()
    .max(20, 'Van is te lank')
    .min(1, { message: 'Van word benodig' })
    .refine((name) => !/\d/.test(name), {
      message: 'Van mag nie nommers bevat nie'
    })
    .refine((name) => /[a-zA-Z]/.test(name), {
      message: 'Van moet ten minste een letter bevat'
    }),
  parentEmail: z.string().email({ message: 'Ongeldige e-posadres' }),
  parentCellphone: z
    .string()
    .optional()
    .nullable()
    .refine(
      (mobile) =>
        mobile === null ||
        mobile === undefined ||
        /^\+?[1-9]\d{10}$/.test(mobile) ||
        /^\d{10}$/.test(mobile),
      {
        message: 'Selfoon nommer is ongeldig'
      }
    ),
  parentIdNumber: z
    .string()
    .min(10, { message: 'ID nommer moet langer as 10 nommers wees' })
    .max(13, { message: 'ID nommer mag nie langer as 13 nommers wees' })
    .refine((idNumber) => !isNaN(Number(idNumber)), {
      message: 'ID nommer mag net nommers bevat'
    })
})
