import {
  Box,
  Center,
  GridItem,
  GridItemProps,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spinner
} from '@chakra-ui/react'
import { FiRefreshCcw } from 'react-icons/fi'

type Props = GridItemProps & {
  readonly children: React.ReactNode
  readonly title: string
  readonly onRefresh?: () => void
  readonly isLoading?: boolean
}

export function DeveloperToolGridItem({
  title,
  onRefresh,
  isLoading,
  ...props
}: Props) {
  const { children, ...rest } = props
  return (
    <GridItem
      border="1px solid"
      borderColor="secondary.500"
      _hover={{ boxShadow: 'xl' }}
      overflow="hidden"
      {...rest}
    >
      <Center h="10%">
        <HStack>
          <Heading as="h3" size="md" color="brand.300">
            {title}
          </Heading>
          {onRefresh && (
            <IconButton
              icon={<Icon as={FiRefreshCcw} />}
              aria-label="Refresh"
              variant="no-fill"
              color="secondary.200"
              onClick={onRefresh}
            />
          )}
        </HStack>
      </Center>
      <Box h="90%" w="100%" overflow="scroll">
        {isLoading ? (
          <Center h="100%" data-testid="loader">
            <Spinner />
          </Center>
        ) : (
          children
        )}
      </Box>
    </GridItem>
  )
}
