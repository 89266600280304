import { FlashcardSubmitType } from '@/types/flashcardWords'
import { z } from 'zod'

export const FlashcardsModalDefaultValues: FlashcardSubmitType = {
  vraagbankGradeId: '',
  vraagbankSubjectId: '',
  vraagbankContentAreaId: '',
  flashcards: [],
  tags: []
}

export const FlashcardsModalSchema = z.object({
  vraagbankGradeId: z.string().uuid({ message: 'Graad word benodig' }),
  vraagbankSubjectId: z.string().uuid({ message: 'Vak word benodig' }),
  vraagbankContentAreaId: z
    .string()
    .uuid({ message: 'Inhoudsarea word benodig' }),
  flashcards: z
    .array(
      z.tuple([
        z
          .string()
          .min(1, { message: 'Een of meer flitsgids het geen woord nie' }), // Ensure the word is present
        z
          .string()
          .min(1, { message: 'Een of meer flitsgids het geen definiesie nie' }) // Ensure the definition is present
      ])
    )
    .min(1, { message: 'Minstens een flitskaart is vereis' })
    .nonempty({ message: "Laai 'n CSV op met die worde en definisies" }),
  tags: z
    .array(
      z.object({
        value: z.string(),
        label: z.string()
      })
    )
    .optional()
})
