import theme from '@/theme'
import { useMediaQuery } from '@chakra-ui/react'

export const useResponsiveness = () => {
  const [isMobile, isTablet] = useMediaQuery([
    `(max-width: ${theme.breakpoints['md']})`,
    `(max-width: ${theme.breakpoints['lg']})`
  ])
  return { isMobile, isTablet, isTabletOrMobile: isMobile || isTablet }
}
