export enum ENVIRONMENTS {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  STAGING = 'staging'
}

/**
 * Making this true for now so that bugsnag won't be initialized
 * We are waiting for API keys from the client
 */
export const IS_LOCAL =
  location.hostname === 'localhost' || location.hostname === '127.0.0.1'
