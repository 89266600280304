import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Grid,
  useDisclosure
} from '@chakra-ui/react'
import { useEventListener } from 'usehooks-ts'
import { ActiveCronJobs } from './DeveloperTools/ActiveCronJobs'

export function DeveloperToolsDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  /**
   * Handles the key press event.
   * If the meta key and 'k' key are pressed, it toggles the open state of the component.
   * @param event - The keyboard event object.
   */
  function handleKeyPress(event: KeyboardEvent) {
    if (event.metaKey && event.key === 'k') {
      event.preventDefault()
      if (isOpen) {
        onClose()
        return
      }
      onOpen()
    }
  }

  useEventListener('keydown', handleKeyPress)

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent color="secondary.300" p={7}>
        <DrawerCloseButton />
        <Grid
          templateColumns="repeat(2, 1fr)"
          templateRows="repeat(2, 1fr)"
          gap={6}
          p={4}
          w="100%"
          h="100%"
        >
          <ActiveCronJobs />
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}
