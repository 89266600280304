import { z } from 'zod'

type FormValues = {
  associationDatePaidUp: string
}

export const removeSchema = z.object({
  associationDatePaidUp: z.string()
})

const pastDate = new Date()
pastDate.setDate(pastDate.getDate() - 1)

export const removeDefaultValues: FormValues = {
  associationDatePaidUp: pastDate.toISOString()
}
