import { SWR_KEYS } from '@/constants'
import { fetcher } from '@/lib/api'
import { InputOption, LookupsEntry } from '@/types'
import { API_ROUTES } from '@/utils'
import { useMemo } from 'react'
import useSWR from 'swr'

export function useSchools(): {
  schoolOptions: InputOption[]
  data: LookupsEntry[]
  loadingSchools: boolean
  error: any
} {
  const { data, error, isLoading } = useSWR(SWR_KEYS.schools, () =>
    fetcher(API_ROUTES.lookups.schools)
  )

  const schoolOptions: InputOption[] = useMemo(() => {
    if (!data) return []
    return data.map((item: LookupsEntry) => ({
      label: item.title,
      value: item.id
    }))
  }, [data])

  return {
    schoolOptions,
    data: data ?? [],
    loadingSchools: isLoading,
    error
  }
}
