import { useAuth } from '@/context/AuthProvider'
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/navigation/routes'
import { images } from '@/theme'
import { Box, Image, ImageProps } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

function Logo({ ...rest }: ImageProps): ReactElement {
  let { isAuthenticated } = useAuth()
  let navigate = useNavigate()

  return (
    <Box
      onClick={() =>
        navigate(
          isAuthenticated ? PROTECTED_PATHS.dashboard : PUBLIC_PATHS.login
        )
      }
      cursor="pointer"
    >
      <Image {...rest} src={images.wolkskool} aria-label="Logo" />
    </Box>
  )
}

export default Logo

Logo.defaultProps = {
  width: 20
}
