import { StatusCode } from '@/types'
import { NEW_INHOUD_PERMISSIONS_API_ERROR_MESSAGES } from './new-inhoud-permissions'
import { WOLKSKOOL_USERS_API_ERROR_MESSAGES } from './wolkskool-user'

export const ERROR_MESSAGES = {
  auth: {
    genericLogin: 'Kon nie in teken nie.',
    genericLogout: 'Kon nie uit teken nie.',
    expiredToken: 'Sessie is nie meer geldig nie, Teken weer in.',
    invalidToken: 'Die sessie kon nie geverifieer word nie, kontak SOS',
    incorrectCredentials: 'Verkeerde epos of wagwoord, probeer weer.'
  },
  adminUsers: {
    create: 'Kon nie administrateur skep nie',
    register: 'Kon nie registreer nie',
    findAll: 'Kon nie administrateurs haal nie',
    update: 'Kon nie administrateur opdateer nie',
    remove: 'Kon nie administrateur verwyder nie',
    sendResetPasswordEmail: 'Kon nie wagwoord herstel e-pos stuur nie',
    resendRegisterEmail: 'Kon nie registreer e-pos stuur nie',
    resetPassword: 'Kon nie wagwoord herstel nie',
    generateLoginAsToken: (userName: string) =>
      `Kon nie in teken as ${userName} nie.`,
    approveRequest: 'Kon nie versoek goedkeur nie',
    cancelRequest: 'Kon nie versoek kanselleer nie'
  },
  associationPayments: {
    create: 'Kon nie verenigingsbetaling skep nie',
    update: 'Kon nie verenigingsbetaling opdateer nie',
    delete: 'Kon nie verenigingsbetaling verwyder nie',
    checkIfExist: 'Kon nie verenigingsbetaling verifieër nie.'
  },

  teachers: {
    update: 'Kon nie onderwyser opdateer nie'
  },
  learners: {
    update: 'Kon nie leerder opdateer nie'
  },
  paymentPackages: {
    update: 'Kon nie subskripsiepakket wysiging skeduleer nie',
    cancel: 'Kon nie geskeduleerde subskripsiepakket wysiging kanselleer nie'
  },
  idType: {
    findAll: 'Kon nie ID tipes haal nie'
  },
  userJobs: {
    findAll: 'Kon nie gebruiker se werk haal nie'
  },

  jobs: {
    findAll: `Kon nie "cron jobs" haal nie`
  },
  provinces: {
    findAll: 'Kon nie provinsies haal nie'
  },
  grades: {
    findAll: 'Kon nie die grade gaan haal nie'
  },
  vraagbankGrades: {
    findAll: 'Kon nie die vraagbank grade gaan haal nie'
  },
  subjects: {
    findAll: 'Kon nie die vakke gaan haal nie'
  },
  flashcards: {
    csvFormat:
      'Die formaat van die csv is nie korrek nie. Kontroleer die formaat en probeer weer.',
    update: 'Kon nie flitskaart opdateer nie',
    remove: 'Kon nie flitskaart verwyder nie'
  },
  vouchers: {
    create: 'Kon nie die afslagkode skep nie',
    update: 'Kon nie die afslagkode opdateer nie',
    remove: 'Kon nie die afslagkode verwyder nie',
    findAllUsersRedeemed:
      'Kon nie die gebruikers wat die afslagkode ingewissel het gaan haal nie'
  },
  vouchersAndCoupons: {
    findAll: 'Kon nie die aflslagkodes gaan haal nie'
  },
  unauthorized: 'Ongemagtigde poging.',
  generic:
    'Iets onbekend het fout gegaan. Kontak die SovTech span as dit voortduur.'
}

export const TRANSLATED_MESSAGES = {
  adminUsers: {
    UniqueConstraintViolationException: `Daar is alreeds 'n administrateur met hierdie e-pos`,
    'Cannot delete yourself': 'Kan nie jouself verwyder nie'
  } as Record<string, string>
}

// Default response for API errors that are not clear for the user
const defaultResponse = 'Iets het foutgegaan'

export const DEFAULT_API_ERRORS: Record<StatusCode, string> = {
  400: defaultResponse,
  401: 'Ongemagtigde poging',
  402: 'Betaling benodig',
  403: 'Jy het nie permissie hiervoor nie',
  404: 'Kon nie gevind word nie',
  405: 'Metode nie toegelaat nie',
  409: defaultResponse,
  503: defaultResponse + ', probeer weer later'
  // TODO: Add more 400 and 500 errors
}

export const API_ERROR_MESSAGES = {
  ...WOLKSKOOL_USERS_API_ERROR_MESSAGES,
  ...NEW_INHOUD_PERMISSIONS_API_ERROR_MESSAGES
}
