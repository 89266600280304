export const colors = {
  brand: {
    50: '#FEF1E7',
    100: '#FDE6D3',
    200: '#FBCDA7',
    300: '#F9B47B',
    400: '#F79B4F',
    500: '#F58221',
    600: '#D7660A',
    700: '#A14C07',
    800: '#6B3305',
    900: '#361902',
    950: '#180C01'
  },
  secondary: {
    50: '#E8EBED',
    100: '#D1D7DB',
    200: '#A4AFB7',
    300: '#768793',
    400: '#515E67',
    500: '#2D3439',
    600: '#242A2E',
    700: '#1B1F22',
    800: '#121517',
    900: '#090A0B',
    950: '#040506'
  }
}
