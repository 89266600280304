import { ERROR_MESSAGES } from '@/constants'
import API from '@/lib/api'
import {
  APIResponse,
  CreateAssociationPaymentValues,
  UpdateAssociationPaymentValues
} from '@/types'
import { bugsnagLogger } from '@/utils'
import API_ROUTES from '@/utils/apiRoutes'

const findOne = async (id: string) => {
  try {
    const { data } = await API.get(API_ROUTES.associationPayments.record(id))

    if (data.error) {
      throw new Error(data.error)
    }

    return {
      data: data.data,
      error: null
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/associationPayments.findOne',
      additionalMetadataValues: { id }
    })
    return {
      data: null,
      error: ERROR_MESSAGES.generic
    }
  }
}

const create = async (values: CreateAssociationPaymentValues) => {
  try {
    const { data } = await API.post(
      API_ROUTES.associationPayments.records,
      values
    )

    if (data.error) {
      throw new Error(data.error)
    }

    return {
      data: data.data,
      error: null
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/associationPayments.create',
      additionalMetadataValues: { values }
    })
    return {
      data: null,
      error: ERROR_MESSAGES.associationPayments.create
    }
  }
}

const update = async (values: UpdateAssociationPaymentValues) => {
  const { id, ...rest } = values
  try {
    const { data } = await API.patch(
      API_ROUTES.associationPayments.record(id),
      rest
    )

    if (data.error) {
      throw new Error(data.error)
    }

    return {
      data: data.data,
      error: null
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/associationPayments.update',
      additionalMetadataValues: { values }
    })
    return {
      data: null,
      error: ERROR_MESSAGES.associationPayments.update
    }
  }
}

const remove = async (
  id: string,
  associationDatePaidUp: string
): Promise<{ data: null | undefined; error: string | null }> => {
  try {
    const { data: responseData } = await API.delete(
      API_ROUTES.associationPayments.recordRemove(id, associationDatePaidUp)
    )

    // in this case we are receiving null from the BE no need to pass a data type
    const { status, error, message, data }: APIResponse<any> = responseData

    if (status === 'ERROR') {
      if (error === 404) {
        return {
          data: null,
          error: ERROR_MESSAGES.associationPayments.delete
        }
      } else if (error === 401) {
        return { data: null, error: ERROR_MESSAGES.unauthorized }
      } else {
        throw new Error(message)
      }
    }

    return {
      data: data,
      error: null
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/associationPayments.remove',
      additionalMetadataValues: { id, associationDatePaidUp }
    })
    return { data: null, error: ERROR_MESSAGES.generic }
  }
}

const checkIfExist = async (reference: string) => {
  try {
    const { data } = await API.get(
      API_ROUTES.associationPayments.checkIfExist(reference)
    )

    if (data.error) {
      throw new Error(data.error)
    }

    return {
      data: data.data,
      error: null
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/associationPayments.checkIfExist',
      additionalMetadataValues: { reference }
    })
    return {
      data: null,
      error: ERROR_MESSAGES.associationPayments.checkIfExist
    }
  }
}

export const AssociationPaymentsService = {
  findOne,
  create,
  update,
  remove,
  checkIfExist
}
