import { IS_LOCAL } from '@/constants'
import Bugsnag from '@bugsnag/js'

type NotifyProps = {
  error: any
  scope?: string
  additionalMetadataValues?: Record<string, unknown>
}

class BugsnagLogger {
  setUser(id: string, email: string, name: string) {
    if (IS_LOCAL) {
      console.info({ id, email, name })
      return
    }
    Bugsnag.setUser(id, email, name)
  }

  notify = ({ error, scope, additionalMetadataValues }: NotifyProps) => {
    const metadata = { scope, ...additionalMetadataValues }
    if (IS_LOCAL) {
      console.info({ error, metadata })
      return
    }
    Bugsnag.notify(error, (event) => {
      event.addMetadata('Metadata', metadata)
    })
  }
}

export const bugsnagLogger = new BugsnagLogger()
