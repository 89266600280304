import {
  DatePicker,
  Select,
  TextInput,
  Textarea
} from '@/components/FormElements'
import { useAssociationsPayment, useDebounce, useSchools } from '@/hooks'
import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { z } from 'zod'
import {
  getInputValidations,
  getTextAreaValidationSchema,
  handleInputOnChange,
  handleInputOnKeyDown,
  handleReferenceCheckIfExist,
  updateForm
} from '../associationPayments.helpers'

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

type FormValues = {
  reference: string
  schoolId: string
  datePaidUp: string
  emailTextarea: string
  [key: string]: string
}

const createDefaultValues: FormValues = {
  reference: '',
  schoolId: '',
  datePaidUp: oneYearFromNow.toISOString(),
  emailTextarea: ''
}

type Props = {
  isOpen: boolean
  onClose: () => void
}
export const CreateAssociationPaymentModal = ({ isOpen, onClose }: Props) => {
  const { create, checkIfExist } = useAssociationsPayment()

  const { data: schools, loadingSchools: schoolsIsLoading } = useSchools()

  const schoolsOptions = schools?.map((school) => ({
    value: school.id,
    label: school.title
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [emailObjects, setEmailObjects] = useState<Record<string, string>>({})
  const emails = useMemo(() => Object.values(emailObjects), [emailObjects])

  const createSchema = useMemo(() => {
    // Text area validation schema
    const textAreaValidationSchema = getTextAreaValidationSchema(
      'emailTextarea',
      emailObjects
    )

    // Dynamic input fields validations
    const inputValidations = getInputValidations(emailObjects)

    // Combine the text area validation with the dynamic email validations
    return z.object({
      reference: z.string().min(1, { message: 'Verwysing word benodig' }),
      schoolId: z.string().min(1, { message: "Kies 'n skool" }),
      datePaidUp: z.string().refine((val) => new Date(val) > new Date(), {
        message: 'Datum moet in die toekoms wees'
      }),
      ...textAreaValidationSchema.shape,
      ...inputValidations
    })
  }, [emailObjects])

  const methods = useForm({
    defaultValues: createDefaultValues,
    resolver: zodResolver(createSchema)
  })

  const handleDebouncedReferenceCheckIfExist = useDebounce(
    handleReferenceCheckIfExist,
    {
      delay: 1000
    }
  )

  const onSubmit: SubmitHandler<typeof createDefaultValues> = (values) => {
    const { reference, schoolId, datePaidUp, emailTextarea, ...rest } = values
    const emails = emailTextarea
      ? new Set([...Object.values(rest), emailTextarea])
      : new Set(Object.values(rest))

    setIsLoading(true)

    // Check if reference already exists
    checkIfExist(reference).then((doesExist) => {
      if (doesExist) {
        methods.setError('reference', {
          type: 'manual',
          message: 'Verwysing bestaan alreeds'
        })
        setIsLoading(false)
      } else {
        // Reference does not exist, proceed with form submission
        create({
          reference,
          schoolId,
          datePaidUp: new Date(datePaidUp),
          usersEmails: Array.from(emails)
        })
          .then(() => {
            handleOnClose()
          })
          .catch(() => {
            // need to catch this to not submit, the error is being handled by the hook
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    })
  }

  const handleOnClose = () => {
    setEmailObjects({})
    methods.reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Registreer 'n nuwe betaling</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <Stack>
              <TextInput
                label="Verwysing"
                name="reference"
                onChange={(e) => {
                  const value = e.target.value.toUpperCase()
                  handleDebouncedReferenceCheckIfExist(
                    methods,
                    checkIfExist,
                    value
                  )
                  methods.setValue('reference', value)
                }}
              />
              <Select
                label="Skool"
                name="schoolId"
                isLoading={schoolsIsLoading}
                options={schoolsOptions}
                setSelectedOption={(option) =>
                  methods.setValue('schoolId', option.value)
                }
              />
              <DatePicker
                name="datePaidUp"
                helperText="Een jaar van nou af word by verstek gebruik"
                label="Datum"
                dateFormat="dd-MM-yyyy"
                minDate={new Date()}
                date={new Date(methods.watch('datePaidUp'))}
                onApply={(date) => {
                  if (date) {
                    methods.setValue('datePaidUp', date.toISOString())
                  }
                }}
                onChange={(date) => {
                  if (date) {
                    methods.setValue('datePaidUp', date.toISOString())
                  }
                }}
              />
              <Textarea
                label="E-pos addresse"
                name="emailTextarea"
                height="85px"
                placeholder={`voorbeeld@epos.co.za \nvoorbeeld@epos.co.za \nvoorbeeld@epos.co.za`}
                onChange={(e) => {
                  updateForm(
                    'emailTextarea',
                    emails,
                    e.target.value,
                    methods.setValue,
                    setEmailObjects
                  )
                }}
              />
              <Grid
                templateColumns="repeat(3, 1fr)"
                gap={2}
                mt={2}
                maxH="75vh"
                overflow="scroll"
              >
                {Object.keys(emailObjects).map((key) => {
                  const isInvalid = Object.keys(
                    methods.formState.errors
                  ).includes(key)
                  return (
                    <TextInput
                      key={key}
                      mb={isInvalid ? undefined : 0}
                      name={key}
                      onKeyDown={(e) =>
                        handleInputOnKeyDown(
                          e,
                          key,
                          emailObjects,
                          setEmailObjects
                        )
                      }
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase().trim()
                        methods.setValue(key, value)
                        handleInputOnChange(
                          value,
                          key,
                          emailObjects,
                          setEmailObjects
                        )
                      }}
                    />
                  )
                })}
              </Grid>
            </Stack>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            isDisabled={isLoading}
          >
            Skep
          </Button>
          <Button
            onClick={handleOnClose}
            isDisabled={isLoading}
            variant="brand-outline"
            ml={2}
          >
            Kanselleer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
