import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const variants = defineStyle({
  brand: {
    border: '1px solid',
    borderColor: 'brand.500',
    color: 'secondary.600',
    backgroundColor: 'brand.500',
    rounded: 'none',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 2px 20px 0px',
    _hover: {
      border: '1px solid',
      borderColor: 'brand.500',
      backgroundColor: 'transparent',
      color: 'brand.500'
    },
    _groupHover: {
      border: '1px solid',
      borderColor: 'brand.500',
      backgroundColor: 'transparent',
      color: 'brand.500'
    },
    _active: {
      bg: 'brand.500'
    },
    _focus: {
      boxShadow: 'none'
    }
  },
  'brand-outline': {
    color: 'brand.500',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'brand.500',
    borderRadius: 0,
    my: 1,
    _hover: {
      color: 'secondary.600',
      backgroundColor: 'brand.500'
    },
    _active: {
      color: 'brand.200'
    },
    _focus: {}
  },
  'no-fill': {
    color: 'brand.500',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    my: 1,
    _hover: {
      color: 'brand.200'
    },
    _active: {
      color: 'brand.200'
    },
    _focus: {}
  },
  red: {
    border: '1px solid',
    borderColor: 'red.500',
    color: 'secondary.600',
    backgroundColor: 'red.500',
    rounded: 'none',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 2px 20px 0px',
    _hover: {
      border: '1px solid',
      borderColor: 'red.500',
      backgroundColor: 'transparent',
      color: 'red.500'
    },
    _groupHover: {
      border: '1px solid',
      borderColor: 'red.500',
      backgroundColor: 'transparent',
      color: 'red.500'
    },
    _active: {
      bg: 'red.500'
    },
    _focus: {
      boxShadow: 'none'
    }
  }
})

const lg = defineStyle({
  fontSize: 'lg'
})

const sm = defineStyle({
  fontSize: '10px'
})

export const buttonTheme = defineStyleConfig({
  variants,
  sizes: { lg, sm },
  defaultProps: {
    variant: 'brand'
  }
})
