import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ENVIRONMENTS } from '../config'
import { ENV_VARS } from '../env'

export const bugsnagConfig = {
  apiKey: ENV_VARS.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: APP_VERSION,
  releaseStage: ENV_VARS.ENVIRONMENT,
  enabledReleaseStages: [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.STAGING]
}
