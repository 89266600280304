import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
  caption: {},
  table: { bg: 'secondary.600' },
  tbody: {},
  td: {},
  thead: {
    color: 'brand.400'
  },
  th: {},
  tr: {
    borderBottom: '1px solid',
    borderColor: 'secondary.400'
  },
  tfoot: {}
})

const defaultProps = {
  variant: 'unstyled'
}

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps
})
