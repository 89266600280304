import { z } from 'zod'

export const defaultValues = {
  newDescription: undefined,
  newSlogan: undefined,
  newAmount: undefined,
  scheduleDate: null,
  executeNow: false
}

export const updateSchema = z.object({
  newDescription: z.string().nullish(),
  newSlogan: z.string().nullish(),
  newAmount: z.string().nullish(),
  scheduleDate: z.date().nullable(),
  executeNow: z.boolean()
})
