import { SuspenseWrapper } from '@/components'
import { PageWrapper } from '@/layouts'
import { LazyExoticComponent } from 'react'
import {
  Outlet,
  PathRouteProps,
  RouteObject as ReactRouteObject
} from 'react-router-dom'

export interface RouteObject extends PathRouteProps {
  title: string
  Page: LazyExoticComponent<() => React.JSX.Element>
  role?: string
  routes?: RouteObject[]
}

type BuildComponentProps = {
  role: string
  title: string
  Page: LazyExoticComponent<() => React.JSX.Element>
}

export const buildPublicRoute = ({
  title,
  Page,
  path,
  handle,
  routes
}: RouteObject): ReactRouteObject => {
  const Component = (
    <SuspenseWrapper>
      <PageWrapper title={title}>
        <Page />
        <Outlet />
      </PageWrapper>
    </SuspenseWrapper>
  )
  const routeObject: ReactRouteObject = {
    path,
    handle
  }

  if (routes?.length) {
    let childRoutes = routes.map((childRoute: RouteObject) =>
      buildPublicRoute(childRoute)
    )
    childRoutes = [
      {
        index: true,
        element: Component
      },
      ...childRoutes
    ]

    routeObject.children = childRoutes
  } else {
    routeObject.element = Component
  }

  return routeObject
}

export const buildProtectedComponent = ({
  title,
  role,
  Page
}: BuildComponentProps) => {
  // Add logic here to render component wrapped in a Roleguard or not
  return (
    <SuspenseWrapper>
      <PageWrapper title={title}>
        <Page />
        <Outlet />
      </PageWrapper>
    </SuspenseWrapper>
  )
}

export const buildProtectedRoute = (route: RouteObject): ReactRouteObject => {
  const { Page, role, title, path, routes, handle } = route

  const Component = buildProtectedComponent({
    title,
    role: role ?? '',
    Page
  })

  const routeObject: ReactRouteObject = {
    path,
    handle
  }

  let childRoutes =
    routes?.map((childRoute: RouteObject) =>
      buildProtectedRoute({
        ...childRoute,
        role
      })
    ) ?? []

  if (routes?.length) {
    childRoutes = [
      {
        index: true,
        element: Component
      },
      ...childRoutes
    ]

    routeObject.children = childRoutes
  } else {
    routeObject.element = Component
  }

  return routeObject
}
