import { ERROR_MESSAGES } from '@/constants'
import theme from '@/theme'
import { bugsnagLogger } from '@/utils/bugsnagLogger'
import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Text
} from '@chakra-ui/react'
import papa from 'papaparse'
import { useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'

type Props = {
  setFileData: (fileData: string[][]) => void
  name: string
}

// create a new file reader
const reader = new FileReader()

export const UploaderCSV = ({ setFileData, name }: Props) => {
  const [csvHeading, setCsvHeading] = useState<boolean>(true)
  const { control } = useFormContext()
  let parsedCopy: string[][] = []

  const [file, setFile] = useState<File | undefined>(undefined)
  // handler for file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // get the file
    const file = event.target.files?.[0]
    setFile(file)

    // set the onload event for the reader
    reader.onload = (e) => {
      const contents = e.target?.result

      // parse the csv file
      const parsed = papa.parse(contents as string, { skipEmptyLines: true })
        .data as string[][]
      parsedCopy = [...parsed]

      // remove the heading if the user does not want it
      if (csvHeading) {
        parsedCopy.shift()
      }
      setFileData(parsedCopy)
    }

    // read the file as text if there is a file
    if (file) {
      reader.readAsText(file)
    }
  }

  const {
    fieldState: { error }
  } = useController({
    control,
    name
  })

  const showError = !!error
  const errors = error as any

  // we need to get the error message from the error object
  // since we have nested validation from zod
  const getError = (error: any) => {
    try {
      if (error) {
        if (error?.message) {
          return error.message
        } else if (errors[0] !== undefined) {
          return errors[0][1].message
        } else if (errors[1] !== undefined) {
          return errors[1][0].message
        }
        throw new Error()
      }
    } catch (error) {
      bugsnagLogger.notify({
        error,
        scope: 'CSVUploader.getError',
        additionalMetadataValues: { parsedFileData: parsedCopy, error }
      })
      return ERROR_MESSAGES.flashcards.csvFormat
    }
  }

  return (
    <FormControl isInvalid={showError} label="Laai CSV">
      <HStack>
        <FormLabel>Laai CSV</FormLabel>
        <Spacer />
        <Checkbox
          aria-label="Opskrifte"
          defaultChecked
          mb={2}
          onChange={(check) => {
            setCsvHeading(check.target.checked)
          }}
        >
          Opskrifte
        </Checkbox>
      </HStack>
      <Box
        position="relative"
        cursor="pointer"
        height="50px"
        width="100%"
        border={`1px solid ${theme.colors.brand['500']}`}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg={theme.colors.secondary['600']}
      >
        <Text>{file ? file.name : 'Sleep hierheen of klik om te laai.'}</Text>
        {/* Render input over the box keeps drag and drop functionality */}
        <Input
          data-testid="csv-upload-input"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          opacity="0"
          cursor="pointer"
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          aria-hidden="true"
          aria-label="Laai CSV"
        />
      </Box>
      {showError && <FormErrorMessage>{getError(errors)}</FormErrorMessage>}
    </FormControl>
  )
}
