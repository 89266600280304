import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'
import { buttonTheme } from './button'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    backdropFilter: 'auto',
    backdropBlur: '5px'
  },
  dialog: {
    borderRadius: 0,
    bg: `secondary.500`
  },
  closeButton: {
    ...buttonTheme.variants?.['brand-outline']
  }
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle
})
