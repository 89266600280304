import theme from '@/theme'
import styled from '@emotion/styled'

/**
 * For a complete list of styles
 * @see https://www.npmjs.com/package/react-datepicker?activeTab=code
 * Then navigate to: /react-datepicker/dist/react-datepicker.css
 */

export const DatePickerWrapper = styled.div`
  .react-datepicker {
    font-family: Arial, sans-serif;
    border: none;
    background-color: transparent;
    width: 100%;
    color: ${theme.colors.brand[300]};
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding-top: 0;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__day-names {
    font-weight: bold;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 2rem;
    line-height: 2rem;
    margin: 0.2rem;
    text-align: center;
    color: ${theme.colors.brand[300]};
  }

  .react-datepicker__day--today {
    font-weight: bold;
    color: ${theme.colors.brand[500]};
  }

  .react-datepicker__day--selected {
    background-color: ${theme.colors.brand[500]};
    border-radius: 100%;
    color: #ffffff;
  }
  .react-datepicker__day--selected:hover {
    background-color: ${theme.colors.brand[500]};
  }

  .react-datepicker__day:hover {
    border-radius: 100%;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: unset;
  }

  .react-datepicker__day:not([aria-disabled='true']):hover {
    background-color: ${theme.colors.brand[500]};
    border-radius: 100%;
    color: #ffffff;
  }
`
