import { ERROR_MESSAGES } from '@/constants'
import API from '@/lib/api'
import { AdminUser } from '@/types'
import { API_ROUTES, bugsnagLogger } from '@/utils'

export type LoginProps = {
  email: string
  password: string
}

type loginResponseProps = {
  data: AdminUser | null
  token: string
  error: string | null
}

type validationResponseProps = {
  data: AdminUser | null
  error: string | null
  message?: string
}

const login = async (payload: LoginProps): Promise<loginResponseProps> => {
  try {
    const { data } = await API.post(API_ROUTES.authentication.signIn, payload)
    const token = data.access_token
    const { data: results, error } = await validateToken(token)
    if (error) {
      // add bugsnag
      // if this verification fails that means it is a possible attack on a user
      return {
        data: null,
        token: '',
        error: ERROR_MESSAGES.auth.invalidToken
      }
    }

    return {
      data: results,
      token: token,
      error: null
    }
  } catch (error: any) {
    if (error?.response?.data?.message === 'User not found') {
      return {
        data: null,
        token: '',
        error: ERROR_MESSAGES.auth.incorrectCredentials
      }
    }
    bugsnagLogger.notify({
      error,
      scope: 'services/authentication.login',
      additionalMetadataValues: { payload }
    })
    return {
      data: null,
      token: '',
      error: ERROR_MESSAGES.auth.genericLogin
    }
  }
}

const validateToken = async (
  token: string
): Promise<validationResponseProps> => {
  try {
    const { data } = await API.post(API_ROUTES.authentication.validateToken, {
      token
    })

    // token expired
    if (data?.status === 'ERROR') {
      if (data.message === 'jwt expired') {
        return {
          data: null,
          error: ERROR_MESSAGES.auth.expiredToken
        }
      }
      return {
        data: null,
        error: ERROR_MESSAGES.auth.invalidToken
      }
    }
    // if token valid respond with user data (and message if there is one)
    return {
      data: data.data,
      error: null,
      message: data?.message
    }
  } catch (error) {
    bugsnagLogger.notify({
      error,
      scope: 'services/authentication.validateToken',
      additionalMetadataValues: { token }
    })
    // we need to evaluate this for now so we can improve later
    return {
      data: null,
      error: ERROR_MESSAGES.auth.invalidToken
    }
  }
}

export const AuthenticationService = { login, validateToken }
