import { ToastProps } from '@chakra-ui/react'

export const SUCCESS_TOAST: ToastProps = {
  duration: 6000,
  isClosable: true,
  title: 'Sukses!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: ToastProps = {
  duration: 6000,
  title: 'Oeps!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}
