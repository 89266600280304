import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const brand = defineStyle({
  background: 'inherit',
  border: '1px solid',
  borderRadius: 0,
  borderColor: 'brand.500',
  color: 'brand.300',
  _focus: {
    zIndex: 1,
    borderColor: 'brand.500',
    boxShadow: '0 0 0 1px #F58221' // brand.500 color
  },
  _hover: { borderColor: 'brand.300' },
  _autofill: {
    textFillColor: '#F9B47B', // brand.300 color
    boxShadow: '0 0 0px 100px #242A2E inset', // we can't set background color so we use boxshadow
    caretColor: '#F9B47B' // brand.300 color
  },
  marginBottom: 4
})

export const textareaTheme = defineStyleConfig({
  variants: {
    brand
  },
  defaultProps: {
    variant: 'brand'
  }
})
