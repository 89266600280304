import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const dark = defineStyle({
  color: 'secondary.500',
  backgroundColor: 'secondary.700',
  borderColor: 'secondary.500',
  borderRadius: 0
})

export const kbdTheme = defineStyleConfig({
  variants: { dark },
  defaultProps: {
    variant: 'dark'
  }
})
