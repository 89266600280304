import Logo from '@/components/Logo'
import { CLOSED_WIDTH, OPEN_WIDTH } from '@/constants'
import { useAppContext } from '@/context/AppProvider'

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Kbd,
  Text,
  VStack
} from '@chakra-ui/react'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import LinkItems from '../LinkItems'

type Props = {
  readonly children: React.JSX.Element
}
function Sidebar({ children }: Props) {
  const { drawerOpen, toggleDrawer } = useAppContext()

  /**
   * navigator.platform is deprecated but still usable as stated on the MDN docs:
   *
   * navigator.platform should almost always be avoided in favor of feature detection. But there is one case where, among the
   * options you could use, navigator.platform may be the least-bad option: When you need to show users advice about whether
   * the modifier key for keyboard shortcuts is the ⌘ command key (found on Apple systems) rather than the ⌃ control key (on
   * non-Apple systems)
   *
   * @see https://developer.mozilla.org/docs/Web/API/Navigator/platform
   */

  let modifierKey = '^ ctrl' // control key
  if (navigator.platform.startsWith('Mac')) {
    modifierKey = '⌘ command' // command key
  }

  return (
    <Box data-testid="sidebar">
      <Flex flexDir="column" minHeight="calc(100%-64px)" bg={'gray.900'}>
        <Box
          backgroundColor="secondary.700"
          borderRight="1px"
          borderRightColor="gray.700"
          position="fixed"
          width={drawerOpen ? OPEN_WIDTH : CLOSED_WIDTH}
          height="full"
          transition="0.5s"
          overflow="hidden"
        >
          <Box w="200px">
            <Logo ml={drawerOpen ? 5 : 0} transition="margin 0.5s" />
          </Box>
          <Flex mt={4} mx={4} flexDir="column" height="75%" overflow="scroll">
            <LinkItems py={2} px={4} />
          </Flex>
          <VStack position="absolute" bottom={0} w="100%">
            <Flex
              aria-label="toggle drawer button"
              align="center"
              role="group"
              cursor="pointer"
              onClick={toggleDrawer}
              alignItems="center"
              width="100%"
              ml={drawerOpen ? 8 : 5}
              transition="margin 0.5s"
            >
              <IconButton
                borderRadius={0}
                aria-label="toggle drawer icon"
                fontSize="16px"
                icon={
                  drawerOpen ? (
                    <Icon as={FiArrowLeft} />
                  ) : (
                    <Icon as={FiArrowRight} />
                  )
                }
              />
              {drawerOpen && (
                <Text
                  marginLeft={8}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  _groupHover={{ color: 'brand.400' }}
                >
                  Maak toe
                </Text>
              )}
            </Flex>
            <Box h={10}>
              {drawerOpen && (
                <VStack gap={0}>
                  <Text fontSize={10} color="secondary.500">
                    Programmeerderspaneel
                  </Text>
                  <Text fontSize={11} color="secondary.500">
                    <Kbd>{modifierKey}</Kbd>+<Kbd>K</Kbd>
                  </Text>
                </VStack>
              )}
            </Box>
          </VStack>
        </Box>
      </Flex>
      <Box ml={drawerOpen ? OPEN_WIDTH : CLOSED_WIDTH} transition="0.5s">
        {children}
      </Box>
    </Box>
  )
}

export default Sidebar
