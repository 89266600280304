import {
  DATA_AUTH_TOKEN,
  ERROR_MESSAGES,
  ERROR_TOAST,
  LOGIN_TOAST_ID,
  LOGOUT_TOAST_ID,
  SUCCESS_MESSAGES,
  SUCCESS_TOAST,
  USER_STORAGE_KEY
} from '@/constants'
import { useBrowserStorage } from '@/hooks'
import { setToken } from '@/lib/api'
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/navigation/routes'
import { AuthenticationService } from '@/services'
import { AdminUser, UserLoginTypes } from '@/types'
import { bugsnagLogger } from '@/utils'
import { Center, Spinner, useToast } from '@chakra-ui/react'
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

type AuthProviderProps = {
  isAuthenticated: boolean
  isAuthenticating: boolean
  user: AdminUser | null
  login: ({ email, password, remember }: UserLoginTypes) => void
  logout: () => void
  children: React.ReactNode
}

const AuthContext = createContext<Partial<AuthProviderProps>>({})

export const useAuth = () => useContext(AuthContext)

/*
 * This component is what we'll use to kick out unauthenticated users.
 */
export function RequireAuth({
  children
}: {
  children: React.JSX.Element | React.JSX.Element[]
}) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={PUBLIC_PATHS.login} state={{ from: location }} replace />
    )
  }

  return children
}

function AuthProvider(): ReactElement {
  // bugsnag
  const handleSetBugsnagUser = (userData: AdminUser) => {
    bugsnagLogger.setUser(
      userData.id,
      userData.email,
      `${userData.name} ${userData.surname}`
    )
  }

  // hooks
  const navigate = useNavigate()
  const toast = useToast()

  const [localUser, setLocalUser, removeLocalUser] =
    useBrowserStorage<AdminUser>(USER_STORAGE_KEY, 'local')

  const [sessionUser, setSessionUser, removeSessionUser] =
    useBrowserStorage<AdminUser>(USER_STORAGE_KEY, 'session')

  // local
  const [localDataAuthToken, setLocalDataAuthToken, removeLocalDataAuthToken] =
    useBrowserStorage<string>(DATA_AUTH_TOKEN, 'local')

  // session
  const [
    sessionDataAuthToken,
    setSessionDataAuthToken,
    removeSessionDataAuthToken
  ] = useBrowserStorage<string>(DATA_AUTH_TOKEN, 'session')

  //state hooks
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<AdminUser | null>(
    sessionUser ?? localUser ?? null
  )
  const [dataAuthToken, setDataAuthToken] = useState(
    sessionDataAuthToken ?? localDataAuthToken
  )

  // local and session user storage
  const persistUser = useCallback(
    async (data: any, rememberMe?: boolean) => {
      rememberMe ? setLocalUser(data) : setSessionUser(data)
      setUser(data)
      handleSetBugsnagUser(data)
      // Add bugsnag and logger similar to vraagbank FE
    },
    [setLocalUser, setSessionUser]
  )

  // local and session token storage
  const persistDataAuthToken = useCallback(
    (token: string, rememberMe?: boolean) => {
      rememberMe ? setLocalDataAuthToken(token) : setSessionDataAuthToken(token)
      setDataAuthToken(token)
      // set token to be appended to all requests after successful login
      setToken(token)
    },
    [setLocalDataAuthToken, setSessionDataAuthToken]
  )

  // clear all browser data
  const clearAllBrowserStorage = (
    tokenExpiredMessage: string | null,
    logoutMessage?: string | null
  ) => {
    try {
      removeLocalUser()
      removeSessionUser()
      removeLocalDataAuthToken()
      removeSessionDataAuthToken()
      setUser(null)
      setDataAuthToken(undefined)
      navigate(PUBLIC_PATHS.login)
      if (tokenExpiredMessage && !toast.isActive(LOGIN_TOAST_ID)) {
        toast({
          description: tokenExpiredMessage,
          ...ERROR_TOAST,
          id: LOGIN_TOAST_ID,
          position: 'bottom'
        })
      }
      if (logoutMessage && !toast.isActive(LOGOUT_TOAST_ID)) {
        toast({
          description: logoutMessage,
          ...SUCCESS_TOAST,
          id: LOGOUT_TOAST_ID,
          position: 'bottom'
        })
      }
    } catch (error) {
      // bugsnag log
      toast({
        description: ERROR_MESSAGES.auth.genericLogout,
        ...ERROR_TOAST,
        position: 'bottom'
      })
    }
  }

  // check if token is still valid
  useEffect(() => {
    const reValidateToken = async () => {
      setIsAuthenticating(true)
      if (dataAuthToken) {
        const { data, error } = await AuthenticationService.validateToken(
          dataAuthToken
        )

        if (error) {
          clearAllBrowserStorage(error, null)
        } else if (data) {
          setUser(data)
          handleSetBugsnagUser(data)
          setToken(dataAuthToken)
        }
      }
      setIsAuthenticating(false)
    }
    reValidateToken()

    // clean up function since we are using async await in useEffect
    return () => {}
  }, [dataAuthToken])

  // check if user is stored
  useEffect(() => {
    if (user?.name) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [user])

  const values = { user, login, logout, isAuthenticated, isAuthenticating }

  const memoizedValues = useMemo(() => values, [values])

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.
  if (isAuthenticating) {
    return (
      <Center width="100%" height="100vh">
        <Spinner data-testid="authentication-spinner" />
      </Center>
    )
  }

  async function login({ email, password, remember }: UserLoginTypes) {
    try {
      setIsAuthenticating(true)
      const { data, token, error } = await AuthenticationService.login({
        email,
        password
      })

      if (error) {
        toast({
          description: error,
          ...ERROR_TOAST,
          position: 'bottom'
        })
        navigate(PUBLIC_PATHS.login)
      } else if (data) {
        setUser(data)
        handleSetBugsnagUser(data)
        persistDataAuthToken(token, remember)
        await persistUser(data, remember)
        navigate(PROTECTED_PATHS.dashboard)
      }
      setIsAuthenticating(false)
    } catch (error) {
      // bugsnag log
      toast({
        description: ERROR_MESSAGES.auth.genericLogin,
        ...ERROR_TOAST,
        position: 'bottom'
      })
    }
  }

  function logout() {
    clearAllBrowserStorage(null, SUCCESS_MESSAGES.auth.logout)
  }

  return (
    <AuthContext.Provider value={memoizedValues}>
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthProvider
