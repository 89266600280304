import { EMAIL_REGEX } from '@/constants'
import { UseFormReturn, UseFormSetValue } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'

/**
 * Returns a validation schema for a text area input.
 * @param name - The name of the text area input.
 * @param emailObjects - An object containing email values.
 * @returns A validation schema for the text area input.
 */
export const getTextAreaValidationSchema = (
  name: string,
  emailObjects: Record<string, string>
) =>
  z.object({
    [name]: z.string().refine(
      (value) => {
        // Allow empty value if there are already emails in the emailObjects, otherwise validate with the regex
        return Object.values(emailObjects)?.length && !value
          ? true
          : EMAIL_REGEX.test(value ?? '')
      },
      {
        message: 'Epos is ongeldig'
      }
    )
  })

/**
 * Returns an object containing input validations for each email in the provided emailObjects.
 *
 * @param emailObjects - An object containing email addresses as values, with email keys.
 * @returns An object with email keys as properties and corresponding input validations as values.
 */
export const getInputValidations = (emailObjects: Record<string, string>) =>
  Object.keys(emailObjects).reduce((schema, emailKey) => {
    // Add a new field validation to the schema for each email
    schema[emailKey] = z
      .string()
      .min(1, { message: 'Epos word benodig' })
      .email({ message: 'Epos is ongeldig' })

    return schema
  }, {} as { [key: string]: z.ZodString })

/**
 * Removes an email object from the emailObjects record and updates the state.
 * @param key - The key of the email object to be removed.
 * @param emailObjects - The record of email objects.
 * @param setEmailObjects - The state setter function for emailObjects.
 */
export const removeEmailObject = (
  key: string,
  emailObjects: Record<string, string>,
  setEmailObjects: React.Dispatch<React.SetStateAction<Record<string, string>>>
) => {
  const tempObj = { ...emailObjects }
  delete tempObj[key]
  setEmailObjects(tempObj)
}

/**
 * Handles the onKeyDown event for an input element.
 * If the Backspace key is pressed and there is no value in the input, it removes the input.
 * @param e - The keyboard event.
 * @param key - The key associated with the input element.
 * @param emailObjects - The record of email objects.
 * @param setEmailObjects - The function to set the email objects.
 */
export const handleInputOnKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  key: string,
  emailObjects: Record<string, string>,
  setEmailObjects: React.Dispatch<React.SetStateAction<Record<string, string>>>
) => {
  const backspace = e.key === 'Backspace'
  const target = e.target as HTMLInputElement
  // if backspace is pressed and theres no value in the input, remove the input
  if (backspace && !target.value) {
    target.blur()
    removeEmailObject(key, emailObjects, setEmailObjects)
  }
}

/**
 * Handles the change event of an input field.
 * If the provided value already exists in the emailObjects, it removes the corresponding emailObject.
 * Otherwise, it updates the emailObjects with the new value.
 * @param value - The new value of the input field.
 * @param key - The key associated with the input field.
 * @param emailObjects - The record of email objects.
 * @param setEmailObjects - The setter function to update the emailObjects.
 */
export const handleInputOnChange = (
  value: string,
  key: string,
  emailObjects: Record<string, string>,
  setEmailObjects: React.Dispatch<React.SetStateAction<Record<string, string>>>
) => {
  const emails = Object.values(emailObjects)
  if (emails.includes(value)) {
    removeEmailObject(key, emailObjects, setEmailObjects)
    return
  }
  setEmailObjects((prev) => {
    return { ...prev, [key]: value }
  })
}

/**
 * Updates the form with the provided values.
 * If the emailsString contains multiple emails separated by newlines, it splits them and updates the form with each email.
 * If the emailsString contains a single email, it updates the form with that email.
 * Duplicates and empty values are removed from the emails.
 * The updated form values are set using the setValue function.
 * The email objects are updated using the setEmailObjects function.
 *
 * @param name - The name of the form field to update.
 * @param emails - The array of existing emails.
 * @param emailsString - The string containing the new emails.
 * @param setValue - The function to set the form field value.
 * @param setEmailObjects - The function to set the email objects.
 */
export const updateForm = (
  name: string,
  emails: string[],
  emailsString: string,
  setValue: UseFormSetValue<any>,
  setEmailObjects: React.Dispatch<React.SetStateAction<Record<string, string>>>
) => {
  const multipleEmails = emailsString.split(`\n`)
  if (multipleEmails.length > 1) {
    setValue(name, '')

    // remove duplicates & empty values
    const cleanEmailObjects = Array.from(new Set(multipleEmails)).reduce<
      Record<string, string>
    >((emailObj, email) => {
      if (email.length && !emails.includes(email)) {
        // create unique id to reference each input field
        const trimmedEmail = email.trim().toLowerCase()
        emailObj[`emailInput-${uuidv4()}`] = trimmedEmail
      }
      return emailObj
    }, {})

    Object.entries(cleanEmailObjects).forEach(([key, email]) => {
      setValue(key, email, { shouldDirty: true })
    })
    setEmailObjects((prev) => {
      return { ...prev, ...cleanEmailObjects }
    })
  } else {
    setValue(name, emailsString.toLowerCase(), { shouldDirty: true })
  }
}

/**
 * Checks if the reference already exists.
 *
 * @param methods - The useForm methods object.
 * @param checkIfExist - A function that calls the backend that checks if the reference already exists.
 * @param event - The change event triggered by the input element.
 * @param currentReference - The current reference value (optional).
 */
export const handleReferenceCheckIfExist = (
  methods: UseFormReturn<any, any, undefined>,
  checkIfExist: (reference: string) => Promise<boolean>,
  value: string,
  currentReference?: string
) => {
  if (value && value !== currentReference) {
    checkIfExist(value).then((doesExist) => {
      if (doesExist) {
        methods.setError('reference', {
          type: 'manual',
          message: 'Verwysing bestaan alreeds'
        })
        return
      }
      methods.clearErrors('reference')
    })
  } else {
    methods.clearErrors('reference')
  }
}
