import { Flex, FlexProps } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

type PageWrapProps = FlexProps & {
  title: string
}

function PageWrapper({
  children,
  title,
  ...rest
}: PageWrapProps): React.JSX.Element {
  return (
    <>
      <Helmet title={title} />
      <Flex
        as="main"
        mt={{ base: 16, md: 10, lg: 10 }}
        justifyItems="center"
        h="100%"
        w="100%"
        px={{ base: 1, md: 10, lg: 10 }}
        {...rest}
      >
        {children}
        <Outlet />
      </Flex>
    </>
  )
}

export default PageWrapper
