import { useAppContext } from '@/context/AppProvider'
import { useAuth } from '@/context/AuthProvider'
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/navigation/routes'
import theme from '@/theme'
import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react'
import { BiLogOutCircle } from 'react-icons/bi'

import { FiHome, FiStar } from 'react-icons/fi'
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { PiChalkboardTeacher, PiStudent } from 'react-icons/pi'
import { RiCoupon2Line, RiLightbulbFlashLine } from 'react-icons/ri'
import {
  TbCalendarDollar,
  TbPackages,
  TbReport,
  TbUserDollar,
  TbWorldDollar
} from 'react-icons/tb'
import { NavLink } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

interface LinkItem {
  name: string
  icon: React.ElementType
  href: string
  onClick?: () => void
}

interface LinkGroup {
  heading: string
  items: LinkItem[]
}

export const getLinkItems = (logoutFunction?: () => void): LinkGroup[] => {
  return [
    {
      heading: ' ',
      items: [
        { name: 'Paneelbord', icon: FiHome, href: PROTECTED_PATHS.dashboard }
      ]
    },
    {
      heading: 'Administrasie',
      items: [
        {
          name: 'Administrateurs',
          icon: FiStar,
          href: PROTECTED_PATHS.adminManagement
        }
      ]
    },
    {
      heading: 'Wolkskool',
      items: [
        {
          name: 'Leerders',
          icon: PiStudent,
          href: PROTECTED_PATHS.learnerManagement
        },
        {
          name: 'Onderwysers',
          icon: PiChalkboardTeacher,
          href: PROTECTED_PATHS.teacherManagement
        },
        {
          name: 'Permissiegroepe',
          icon: HiOutlineBuildingOffice2,
          href: PROTECTED_PATHS.wolkskoolPermissionRoles
        },
        {
          name: 'FlitsGids',
          icon: RiLightbulbFlashLine,
          href: PROTECTED_PATHS.flashcards
        }
      ]
    },
    {
      heading: 'Finansies',
      items: [
        {
          name: 'Daaglikse transaksies',
          icon: TbCalendarDollar,
          href: PROTECTED_PATHS.dailyTransactions
        },
        {
          name: 'Gebruikers transaksies',
          icon: TbUserDollar,
          href: PROTECTED_PATHS.wolkskoolUsersTransactions
        },
        {
          name: 'Verenigingbetalings',
          icon: TbWorldDollar,
          href: PROTECTED_PATHS.associationsPayments
        },
        {
          name: 'Subskripsiebestuur',
          icon: TbPackages,
          href: PROTECTED_PATHS.subscriptionManagement
        },
        {
          name: 'Afslagkodes',
          icon: RiCoupon2Line,
          href: PROTECTED_PATHS.coupons
        }
      ]
    },
    {
      heading: 'Verslae',
      items: [
        {
          name: 'Verslae',
          icon: TbReport,
          href: PROTECTED_PATHS.reports
        }
      ]
    },
    {
      heading: ' ',
      items: [
        {
          name: 'Teken uit',
          icon: BiLogOutCircle,
          href: PUBLIC_PATHS.login,
          onClick: () => logoutFunction?.()
        }
      ]
    }
  ]
}

const LinkItems = (rest: FlexProps) => {
  const { drawerOpen } = useAppContext()
  const { logout } = useAuth()

  return (
    <>
      {getLinkItems(logout).map((group, index) => (
        <div key={uuidv4()}>
          {group.heading && (
            <Text
              fontWeight="bold"
              mt={index !== 0 ? 4 : 0}
              mb={1}
              overflow="hidden"
              whiteSpace="nowrap"
              h={4}
            >
              {drawerOpen ? group.heading : ''}
            </Text>
          )}
          {group.items?.map(({ href, icon: IconComponent, name, onClick }) => (
            <NavLink
              onClick={onClick}
              aria-label={`${name} button`}
              key={href}
              to={href}
              style={({ isActive }) => ({
                display: 'block',
                color: isActive ? theme.colors?.brand[200] : 'unset'
              })}
            >
              <Flex
                w="100%"
                align="center"
                justify={drawerOpen ? 'unset' : 'center'}
                cursor="pointer"
                _hover={{
                  border: '1px solid',
                  borderColor: 'brand.500'
                }}
                pl={drawerOpen ? 8 : 4}
                {...rest}
              >
                {IconComponent && (
                  <Icon
                    mr={drawerOpen ? 4 : 'auto'}
                    boxSize={5}
                    as={IconComponent}
                  />
                )}
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  display={drawerOpen ? 'block' : 'none'}
                >
                  {name}
                </Text>
              </Flex>
            </NavLink>
          ))}
        </div>
      ))}
    </>
  )
}

export default LinkItems
