import { DiscountTypes } from '@/types'
import { z } from 'zod'

const stepOneSchema = z.object({
  couponType: z
    .literal(DiscountTypes.FREE_PRODUCT)
    .or(z.literal(DiscountTypes.DISCOUNT))
})

// This step a WIP and is currently disabled
const stepTwoDiscountSchema = z.object({
  selectedCouponType: z.literal(DiscountTypes.DISCOUNT),
  products_discount: z
    .array(z.string(), {
      required_error: 'Ten minste een produk word vereis'
    })
    .min(1, 'Ten minste een produk word vereis')
})

const stepTwoFreeSchema = z.object({
  selectedCouponType: z.literal(DiscountTypes.FREE_PRODUCT),
  products_free: z
    .array(z.string(), {
      required_error: 'Ten minste een produk word vereis'
    })
    .min(1, 'Ten minste een produk word vereis'),
  userTypes_free: z
    .array(z.string(), {
      required_error: 'Ten minste een gebruiker tipe word vereis'
    })
    .min(1, 'Ten minste een gebruiker tipe word vereis'),
  schools_free: z.array(
    z.object({
      value: z.string(),
      label: z.string()
    })
  ),
  startDateValid: z.string().min(1, 'Die begin datum word vereis'),
  redeemEndDateValid: z.string().min(1, 'Die inwissel einddatum word vereis'),
  endDateValid: z.string().min(1, 'Die einddatum word vereis')
})

const stepThreeSchema = z.object({
  code: z
    .string({ required_error: 'Die kode word vereis' })
    .regex(/^[A-Z1-9#]+$/, {
      message:
        'Die kode moet tussen 4 en 10 karakters lank wees en mag slegs A-Z en 1-9 bevat'
    })
    .min(4, 'Die kode moet ten minste 4 karakters lank wees')
    .max(10, 'Die kode mag nie meer dan 10 karakters bevat'),
  description: z
    .string({ required_error: 'Die kode beskrywing word vereis' })
    .min(4, 'Hoe langer die beskrywing, hoe beter!'),
  numberOfCodes: z
    .string({ required_error: 'Die aantal kodes word vereis' })
    .min(1, 'Die aantal kodes moet ten minste 1 wees')
})

const stepTwoSchema = z.discriminatedUnion('selectedCouponType', [
  stepTwoDiscountSchema,
  stepTwoFreeSchema
])

export const createVoucherOrCouponSchema = z.object({
  step1: stepOneSchema,
  step2: stepTwoSchema,
  step3: stepThreeSchema
})

export type CreateVoucherOrCouponSchema = z.infer<
  typeof createVoucherOrCouponSchema
>

const step1DefaultValues = {
  couponType: DiscountTypes.FREE_PRODUCT
}

export const step2DefaultValues = {
  [DiscountTypes.DISCOUNT]: {
    selectedCouponType: DiscountTypes.DISCOUNT as const,
    products_discount: []
  },
  [DiscountTypes.FREE_PRODUCT]: {
    selectedCouponType: DiscountTypes.FREE_PRODUCT as const,
    products_free: [],
    schools_free: [],
    userTypes_free: [],
    startDateValid: '',
    redeemEndDateValid: '',
    endDateValid: ''
  }
}

const step3DefaultValues = {
  code: '',
  numberOfCodes: '',
  description: ''
}

export const createCouponOrVoucherDefaultValues: CreateVoucherOrCouponSchema = {
  step1: {
    ...step1DefaultValues
  },
  step2: {
    ...step2DefaultValues[DiscountTypes.FREE_PRODUCT]
  },
  step3: {
    ...step3DefaultValues
  }
}
