import { extendTheme } from '@chakra-ui/react'
import { animations } from './assets/animations'
import { images } from './assets/images'
import components from './components'
import config from './config'
import { breakpoints, colors, styles, textStyles, zIndices } from './styles'

const theme = extendTheme({
  colors,
  config,
  components,
  textStyles,
  styles,
  breakpoints,
  zIndices
})

export default theme

export { animations, images }
