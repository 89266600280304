import { lazy } from 'react'
import { RouteObject } from './navigation.helpers'

const AdminManagement = lazy(() => import('@/containers/AdminManagement'))
const LearnerManagement = lazy(() => import('@/containers/LearnerManagement'))
const TeacherManagement = lazy(() => import('@/containers/TeacherManagement'))
const Dashboard = lazy(() => import('@/containers/Dashboard'))
const LoginScreen = lazy(() => import('@/containers/LoginScreen'))
const NotFoundScreen = lazy(
  () => import('@/containers/FallbackScreens/NotFoundScreen')
)
const RegisterScreen = lazy(() => import('@/containers/RegisterScreen'))
const ResetPasswordScreen = lazy(
  () => import('@/containers/ResetPasswordScreen')
)
const DailyTransactions = lazy(() => import('@/containers/DailyTransactions'))
const WolkskoolUsersTransactions = lazy(
  () => import('@/containers/WolkskoolUsersTransactions')
)
const AssociationsPayments = lazy(
  () => import('@/containers/AssociationsPayments')
)
const Reports = lazy(() => import('@/containers/Reports'))

const SubscriptionManagement = lazy(
  () => import('@/containers/SubscriptionManagement')
)
const Flashcards = lazy(() => import('@/containers/Flashcards'))
const Coupons = lazy(() => import('@/containers/VouchersAndCoupons'))
const WolkskoolPermissions = lazy(
  () => import('@/containers/WolkskoolPermissions')
)

export const PUBLIC_PATHS = {
  login: '/teken-in',
  register: '/registreer',
  resetPassword: '/herstel-wagwoord'
}

export const PROTECTED_PATHS = {
  dashboard: '/',
  adminManagement: '/administrateurs',
  learnerManagement: '/leerders',
  teacherManagement: '/onderwysers',
  settings: '/settings',
  profile: '/profile',
  notifications: '/notifications',
  support: '/support',
  dailyTransactions: '/daaglikse-transaksies',
  wolkskoolUsersTransactions: '/gebruiker-transaksies',
  associationsPayments: '/verenigingbetalings',
  reports: '/verslae',
  subscriptionManagement: '/subskripsiebestuur',
  flashcards: '/flitsgids',
  coupons: '/afslagkodes',
  wolkskoolPermissionRoles: '/wolkskool-permissiegroepe'
}

export const PUBLIC_ROUTES: RouteObject[] = [
  {
    path: PUBLIC_PATHS.login,
    title: 'Teken in',
    Page: LoginScreen,
    handle: {
      crumb: () => [{ text: 'Teken in' }]
    }
  },
  {
    path: PUBLIC_PATHS.register,
    title: 'Registreer',
    Page: RegisterScreen,
    handle: {
      crumb: () => [{ text: 'Registreer' }]
    }
  },
  {
    path: PUBLIC_PATHS.resetPassword,
    title: 'Herstel wagwoord',
    Page: ResetPasswordScreen,
    handle: {
      crumb: () => [{ text: 'Herstel wagwoord' }]
    }
  }
]

export const PROTECTED_ROUTES: RouteObject[] = [
  {
    path: '/*',
    title: 'Oeps!',
    Page: NotFoundScreen,
    handle: {
      crumb: () => [{ text: 'Oeps!' }]
    }
  },
  {
    path: PROTECTED_PATHS.dashboard,
    title: 'Paneelbord',
    Page: Dashboard,
    handle: {
      crumb: () => [{ text: 'Paneelbord' }]
    }
  },
  {
    path: PROTECTED_PATHS.adminManagement,
    title: 'Administrateurs',
    Page: AdminManagement,
    handle: {
      crumb: () => [{ text: 'Administrateurs' }]
    }
  },
  {
    path: PROTECTED_PATHS.learnerManagement,
    title: 'Leerders',
    Page: LearnerManagement,
    handle: {
      crumb: () => [{ text: 'Leerders' }]
    }
  },
  {
    path: PROTECTED_PATHS.teacherManagement,
    title: 'Onderwysers',
    Page: TeacherManagement,
    handle: {
      crumb: () => [{ text: 'Onderwysers' }]
    }
  },
  {
    path: PROTECTED_PATHS.wolkskoolPermissionRoles,
    title: 'Permissiegroepe',
    Page: WolkskoolPermissions,
    handle: {
      crumb: () => [{ text: 'Permissiegroepe' }]
    }
  },
  {
    path: PROTECTED_PATHS.flashcards,
    title: 'FlitsGids',
    Page: Flashcards,
    handle: {
      crumb: () => [{ text: 'FlitsGids' }]
    }
  },
  {
    path: PROTECTED_PATHS.dailyTransactions,
    title: 'Daaglikse transaksies',
    Page: DailyTransactions,
    handle: {
      crumb: () => [{ text: 'Daaglikse transaksies' }]
    }
  },
  {
    path: PROTECTED_PATHS.wolkskoolUsersTransactions,
    title: 'Gebruiker transaksies',
    Page: WolkskoolUsersTransactions,
    handle: {
      crumb: () => [{ text: 'Gebruiker transaksies' }]
    }
  },
  {
    path: PROTECTED_PATHS.associationsPayments,
    title: 'Verenigingbetalings',
    Page: AssociationsPayments,
    handle: {
      crumb: () => [{ text: 'Verenigingbetalings' }]
    }
  },
  {
    path: PROTECTED_PATHS.reports,
    title: 'Verslae',
    Page: Reports,
    handle: {
      crumb: () => [{ text: 'Verslae' }]
    }
  },
  {
    path: PROTECTED_PATHS.subscriptionManagement,
    title: 'Subskripsiebestuur',
    Page: SubscriptionManagement,
    handle: {
      crumb: () => [{ text: 'Subskripsiebestuur' }]
    }
  },
  {
    path: PROTECTED_PATHS.coupons,
    title: 'Afslagkodes',
    Page: Coupons,
    handle: {
      crumb: () => [{ text: 'Afslagkodes' }]
    }
  }
]
