import { z } from 'zod'

export const loginDefaultValues = {
  email: '',
  password: '',
  remember: false
}

export const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().nonempty(),
  remember: z.boolean().optional()
})
